import { compose } from 'redux';
import { WithFetchDatasByIdFromCMS, WithFetchDatasByIdFromCMSInputProps, WithCMSSEO } from 'modules/shared-cms/hocs';
import { QUERY } from './landing-rubrique-expander-query';
import { LandingRubriqueExpanderLoader } from './landing-rubrique-expander-component-loader';
import { LandingRubriqueExpanderProps } from '.';

const enhancer = compose<React.ComponentType<WithFetchDatasByIdFromCMSInputProps>>(
    WithFetchDatasByIdFromCMS<LandingRubriqueExpanderProps>(
        QUERY,
        LandingRubriqueExpanderLoader,
        'findRubriqueLandingExpanderContent',
    ),
    WithCMSSEO(),
);
export default enhancer;
