import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindShowroomContent($id: String!)
{
  findShowroomContent(id: $id) {
    data {
      
      title {
        iv
      }
      fontSizeTitle {
        iv
      }
      color {
        iv
      }
      showroomPicture {
        iv{
        url
        fileSize
        }
    }
    adress {
        iv
      }
      adressFontSize {
        iv
      }
      phonePicture {
        iv{
        url
        fileSize
        }
    }
    phoneNumber {
      iv
    }
    emailPicture {
      iv{
      url
      fileSize
      }
  }
  emailAdress {
    iv
  }
  ctaText {
    iv
  }
  ctaLink {
    iv
  }
  ctaStyle {
    iv
  }
  targetBlank {
    iv
  }
      
    }
  }
}
`,
]);
