import { CMSContainerField } from 'modules/shared-cms/cms-base-type';
import { readCMSValue } from 'modules/shared-cms/utils';
import { CMSReferenceContainerField } from '../cms-advanced-type';

export const getNestedReferenceId = (
    reference?: CMSReferenceContainerField[] | CMSContainerField<CMSReferenceContainerField[]> | undefined,
): string | undefined => {
    const ref = readCMSValue(reference);
    if (ref && ref.length > 0 && ref[0].id) {
        return ref[0].id;
    }
    return undefined;
};
