/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';

import { buildCMSStyles, extractCMSOrDefault } from 'modules/shared-cms/utils';
import { ClassicButton, CtaButton, HtmlContainer } from 'modules/shared-ui/components';
import {
    ContentPositionProps,
    GlobalFontSizeProps,
    JustifyProps,
    TextColorProps,
} from 'modules/shared-cms/cms-base-type';
import { CMSImageContainer } from 'modules/shared-cms/components';

import BlocActualiteEnhancer from './bloc-actualite-enhancer';
import { BlocActualiteProps } from './bloc-actualite-type';

import styles from './bloc-actualite-component.module.css';

export const BlocActualiteBare: React.FC<BlocActualiteProps> = ({
    title: pTitle,
    titleColor: pTitleColor,
    titleFontSize: pTitleFontSize,
    subtitle: pSubtitle,
    subtitleColor: pSubtitleColor,
    subtitleFontSize: pSubtitleFontSize,
    tagUnselectedBackgroundColor: pTagUnselectedBackgroundColor,
    tagUnselectedColor: pTagUnselectedColor,
    tagBackgroundColor: pTagBackgroundColor,
    tagColor: pTagColor,
    news: pNews,
}) => {
    const {
        title = extractCMSOrDefault(pTitle, ''),
        titleColor = extractCMSOrDefault(pTitleColor, '#000000'),
        titleFontSize = extractCMSOrDefault(pTitleFontSize, 40),
        subtitle = extractCMSOrDefault(pSubtitle, ''),
        subtitleColor = extractCMSOrDefault(pSubtitleColor, '#000000'),
        subtitleFontSize = extractCMSOrDefault(pSubtitleFontSize, 24),
        tagUnselectedBackgroundColor = extractCMSOrDefault(pTagUnselectedBackgroundColor, '#e1c7bc'),
        tagUnselectedColor = extractCMSOrDefault(pTagUnselectedColor, '#ffffff'),
        tagBackgroundColor = extractCMSOrDefault(pTagBackgroundColor, '#a39159'),
        tagColor = extractCMSOrDefault(pTagColor, '#ffffff'),
        allNews = extractCMSOrDefault(pNews, []),
        numberOfItems = 8,
    } = {};

    const [selectedTag, setSelectedTag] = React.useState<string>('');
    const [nbOfRows, setNbOfRows] = React.useState(1);
    const [news, setNews] = React.useState(allNews);
    const [hoveredTag, setHoveredTag] = React.useState<string>('');

    React.useEffect(() => {
        const filteredNews = allNews.filter(n => {
            const tags = extractCMSOrDefault(n.tags, []);
            return selectedTag !== '' ? tags.includes(selectedTag) : true;
        });
        setNews(filteredNews.slice(0, numberOfItems * nbOfRows));
    }, [selectedTag, nbOfRows]);

    // creer un tableau de tags en retirant les doublons
    const tags = Array.from(new Set(news.flatMap(n => extractCMSOrDefault(n.tags, []))));

    const titleStyle = buildCMSStyles<TextColorProps & GlobalFontSizeProps>({
        textColor: titleColor,
        fontSize: titleFontSize,
    });

    const subtitleStyle = buildCMSStyles<TextColorProps & GlobalFontSizeProps>({
        textColor: subtitleColor,
        fontSize: subtitleFontSize,
    });

    return (
        <div>
            <div className={styles.titleBlock}>
                <HtmlContainer htmlContent={title} className={styles.title} style={titleStyle} />
                <ul className={styles.tags}>
                    {tags.map(tag => {
                        const isSelected = selectedTag === tag;
                        return (
                            <li
                                key={tag}
                                className={styles.tag}
                                onClick={() => setSelectedTag(!isSelected ? tag : '')}
                                style={{
                                    backgroundColor:
                                        isSelected || hoveredTag === tag
                                            ? tagBackgroundColor
                                            : tagUnselectedBackgroundColor,
                                    color: isSelected || hoveredTag === tag ? tagColor : tagUnselectedColor,
                                }}
                                onMouseEnter={() => setHoveredTag(tag)}
                                onMouseLeave={() => setHoveredTag('')}
                            >
                                {tag}
                            </li>
                        );
                    })}
                </ul>
                <HtmlContainer className={styles.subtitle} htmlContent={subtitle} style={subtitleStyle} />
            </div>
            <div className={styles.news}>
                {news.length > 0 &&
                    news.map((newsItem, index) => {
                        const nTags = Array.from(new Set(extractCMSOrDefault(newsItem.tags, [])));
                        const nTitleStyle = buildCMSStyles<TextColorProps & GlobalFontSizeProps & JustifyProps>({
                            textColor: newsItem.titleColor,
                            fontSize: newsItem.titleFontSize,
                            justification: newsItem.titleAlign,
                        });
                        const nSubtitleStyle = buildCMSStyles<TextColorProps & GlobalFontSizeProps & JustifyProps>({
                            textColor: newsItem.subtitleColor,
                            fontSize: newsItem.subtitleFontSize,
                            justification: newsItem.subtitleAlign,
                        });

                        return (
                            <a
                                key={`newsItem-${newsItem.title}-${index}`}
                                href={extractCMSOrDefault(newsItem.ctaLink, '')}
                            >
                                <div className={styles.newsItem}>
                                    <CMSImageContainer
                                        picture={newsItem.picture}
                                        style={{ borderRadius: '0 0 100px 0' }}
                                    />
                                    <div>
                                        <ul className={styles.tags}>
                                            {nTags.map(tag => {
                                                const isSelected = selectedTag === tag;
                                                return (
                                                    <li
                                                        key={tag}
                                                        className={styles.tag}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            setSelectedTag(!isSelected ? tag : '');
                                                        }}
                                                        style={{
                                                            backgroundColor:
                                                                isSelected || hoveredTag === tag
                                                                    ? tagBackgroundColor
                                                                    : tagUnselectedBackgroundColor,
                                                            color:
                                                                isSelected || hoveredTag === tag
                                                                    ? tagColor
                                                                    : tagUnselectedColor,
                                                        }}
                                                    >
                                                        {tag}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <div className={styles.newsItemTitle} style={nTitleStyle}>
                                            {newsItem.title}
                                        </div>
                                        <div className={styles.newsItemSubtitle} style={nSubtitleStyle}>
                                            {newsItem.subtitle}
                                        </div>
                                        <div className={styles.btnContainer}>
                                            {newsItem.ctaText && (
                                                <div
                                                    style={buildCMSStyles<ContentPositionProps>({
                                                        horizontalPosition: newsItem.ctaAlign,
                                                    })}
                                                >
                                                    <CtaButton
                                                        ctaText={newsItem.ctaText}
                                                        ctaStyle={newsItem.ctaStyle}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </a>
                        );
                    })}
            </div>
            {allNews.length > numberOfItems * nbOfRows && (
                <div className={styles.seeMore}>
                    <ClassicButton text="Voir plus d'articles" secondary onClick={() => setNbOfRows(nbOfRows + 1)} />
                </div>
            )}
        </div>
    );
};

export const BlocActualite = BlocActualiteEnhancer(BlocActualiteBare);
