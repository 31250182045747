import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindIntroductionContent($id: String!)
{
  findIntroductionContent(id: $id) {
    data {

      title {iv}
      subTitle {iv}
      fontSizeTitle {iv}
      fontSizeSubTitle {iv}
      tagline {iv}
      width {iv}
      textColor {iv}
      color {iv}
      picture { iv { url
      fileSize}}
      ctaText {iv}
      ctaLink {iv}
      ctaStyle {iv}
      ctaText2 {iv}
      ctaLink2 {iv}
      ctaStyle2 {iv}
      horizontalPosition {iv}
      verticalPosition {iv}
      justification {iv}

    }
  }
}`,
]);
