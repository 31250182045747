import { compose } from 'redux';
import { WithFetchDatasByIdFromCMS, WithFetchDatasByIdFromCMSInputProps, WithCMSSEO } from 'modules/shared-cms/hocs';

import { QUERY } from './our-customers-query';
import { OurCustomersLoader } from './our-customers-component-loader';
import { OurCustomersProps } from '.';

const enhancer = compose<React.ComponentType<WithFetchDatasByIdFromCMSInputProps>>(
    WithFetchDatasByIdFromCMS<OurCustomersProps>(QUERY, OurCustomersLoader, 'findNosMariesContent'),
    WithCMSSEO(),
);
export default enhancer;
