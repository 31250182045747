import React from 'react';
import { Placeholder, Responsive } from 'vendors/semantic-ui';

import { configUI } from 'config';

import { useHover } from 'modules/shared/hooks';
import { ClickableContainer, HtmlContainer } from 'modules/shared-ui/components';
import { readCMSValue, getDefaultUrlPicture, getDefault } from 'modules/shared-cms/utils';

import { HoveredImageBlocProps } from './hovered-image-bloc-type';

import styles from './hovered-image-bloc-component.module.css';

export const HoveredImageBloc: React.FC<HoveredImageBlocProps> = React.memo(
    ({ title, picture, link, target, subTitle, style }: HoveredImageBlocProps) => {
        const { hovered, bind } = useHover();
        const pictureValue = getDefaultUrlPicture(picture);

        return (
            <ClickableContainer
                link={readCMSValue(link)}
                target={readCMSValue(target)}
                onHover={bind}
                innerClassName={styles.background}
            >
                <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                    <div className={styles.background} style={style} {...bind}>
                        {pictureValue && <img src={pictureValue} alt={readCMSValue(title)} className={styles.image} />}
                        <div className={styles.mask}>
                            {hovered && <div className={styles.title}>{readCMSValue(title)}</div>}
                            {hovered && subTitle && (
                                <div className={styles.subTitle}>
                                    <HtmlContainer htmlContent={getDefault(readCMSValue(subTitle), '')} />
                                </div>
                            )}
                        </div>
                    </div>
                </Responsive>
                <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                    <div className={styles.background} {...bind}>
                        {pictureValue && <img src={pictureValue} alt={readCMSValue(title)} className={styles.image} />}
                    </div>
                    <div className={styles.title}>{readCMSValue(title)}</div>
                    {subTitle && (
                        <div className={styles.subTitle}>
                            <HtmlContainer htmlContent={getDefault(readCMSValue(subTitle), '')} />
                        </div>
                    )}
                </Responsive>
            </ClickableContainer>
        );
    },
);

export const HoveredImageBlocLoader: React.FC = () => {
    return (
        <Placeholder className={styles.background} style={{ maxWidth: '100%' }}>
            <Placeholder.Image />
        </Placeholder>
    );
};
