import React, { useState } from 'react';

import { Carousel } from 'modules/shared-ui-advanced/components';
import { getDefault, readCMSValue } from 'modules/shared-cms/utils';
import { configUI } from 'config';
import { Responsive } from 'vendors/semantic-ui';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { ClickableContainer } from 'modules/shared-ui/components';
import { LegendBloc, TitleBloc } from '../../components';

import { CarrouselProps } from './carrousel-type';

import enhancer from './carrousel-enhancer';

const carrousel: React.FC<CarrouselProps> = React.memo(({ carouselItems }: CarrouselProps) => {
    const items = getDefault(readCMSValue(carouselItems), []);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

    return (
        <React.Fragment>
            <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                <Carousel cards={1} auto>
                    {carouselItems.iv.slice().map(item => {
                        return (
                            <React.Fragment key={Math.random()}>
                                <TitleBloc isChild {...item} />
                            </React.Fragment>
                        );
                    })}
                </Carousel>
            </Responsive>
            <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                <Carousel cards={1} auto={false} onSlideChange={index => setCurrentSlideIndex(index)}>
                    {items.map(item => {
                        return (
                            <React.Fragment key={Math.random()}>
                                <ClickableContainer link={readCMSValue(item.ctaLink)}>
                                    <CMSImageContainer picture={item.picture} style={{ width: '100%' }} />
                                </ClickableContainer>
                            </React.Fragment>
                        );
                    })}
                </Carousel>
                {items.length > 0 && <LegendBloc {...items[currentSlideIndex]} />}
            </Responsive>
        </React.Fragment>
    );
});

export const Carrousel = enhancer(carrousel);
