import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';

import { buildComponentArray } from 'modules/shared/utils';
import { readCMSValue, buildCMSStyles } from 'modules/shared-cms/utils';
import { BackgroundColorProps } from 'modules/shared-cms/cms-base-type';

import { HoveredImageBloc } from '../hovered-image-bloc';
import { CustomerBlocProps, CustomerBlocItemProps } from './customer-bloc-type';

import styles from './customer-bloc-component.module.css';

export const CustomerBloc: React.FC<CustomerBlocProps> = React.memo(
    ({ title, items, link, color }: CustomerBlocProps) => {
        const uwList = readCMSValue(items);
        return (
            <div className={styles.background} style={buildCMSStyles<BackgroundColorProps>({ color })}>
                <div className={styles.title}>{readCMSValue(title)}</div>

                <div className={styles.container}>
                    {uwList &&
                        buildComponentArray<CustomerBlocItemProps>(
                            uwList,
                            (childItem: CustomerBlocItemProps, key: number): JSX.Element => {
                                return (
                                    <div className={styles.imageContainer} key={key}>
                                        <HoveredImageBloc link={link} {...childItem} />
                                    </div>
                                );
                            },
                        )}
                </div>
            </div>
        );
    },
);

export const CustomerBlocLoader: React.FC = () => {
    return (
        <div className={styles.background}>
            <Placeholder className={styles.title} style={{ maxWidth: '100%' }}>
                <Placeholder.Header>
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <Placeholder>
                <Placeholder.Image style={{ height: '200px' }} />
            </Placeholder>
            <Placeholder>
                <Placeholder.Image style={{ height: '200px' }} />
            </Placeholder>
            <Placeholder>
                <Placeholder.Image style={{ height: '200px' }} />
            </Placeholder>
            <Placeholder>
                <Placeholder.Image style={{ height: '200px' }} />
            </Placeholder>
        </div>
    );
};
