import React from 'react';
import { Container } from 'vendors/semantic-ui';

import { readCMSValue, getDefault } from 'modules/shared-cms/utils';
import { TitleBlocContainer, ZoomedPortfolioBloc } from 'modules/shared-cms-advanced/components';
import { HtmlContainer } from 'modules/shared-ui/components';
import { OurCustomersProps } from '.';

import enhancer from './our-customers-enhancer';
import styles from './our-customers-component.module.css';

const ourCustomers: React.FC<OurCustomersProps> = React.memo(
    ({
        titleBlocPicture,
        description: wrappedDescription,
        customers: wrappedCustomers,
        mention: wrappedMention,
        ...rest
    }: OurCustomersProps) => {
        const {
            description = getDefault(readCMSValue(wrappedDescription), ''),
            customers = getDefault(readCMSValue(wrappedCustomers), []),
            mention = getDefault(readCMSValue(wrappedMention), ''),
        } = {};

        return (
            <React.Fragment>
                <div className={styles.container}>
                    {readCMSValue(titleBlocPicture) && (
                        <div className={styles.titleBlocSection}>
                            <TitleBlocContainer titleBlocPicture={titleBlocPicture} {...rest} />
                        </div>
                    )}
                </div>

                <Container>
                    {description && (
                        <div className={styles.descrptionSection}>
                            <HtmlContainer htmlContent={description} />
                        </div>
                    )}
                    {customers && customers.length > 0 && (
                        <ZoomedPortfolioBloc {...rest} list={readCMSValue(customers)} color={undefined} />
                    )}
                    <div className={styles.mentionSection}>
                        <HtmlContainer htmlContent={mention} />
                    </div>
                </Container>
            </React.Fragment>
        );
    },
);

export const OurCustomers = enhancer(ourCustomers);
