import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindMentionContent($id: String!) {
  findMentionContent(id: $id) {
    data {
      text { iv }
      fontSize { iv }
      textColor { iv }
    }
  }
}
`,
]);
