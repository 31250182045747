import * as React from 'react';

import { getDefault } from 'modules/shared-cms/utils';
import { getNestedReferenceId } from 'modules/shared-cms-advanced/utils';

import { Actualite } from '../../enhanced-blocs';

import { TitleBlocContainer } from '../../components';

import { RubriqueLandingProps } from './rubrique-landing-type';

import enhancer from './rubrique-landing-enhancer';

import styles from './rubrique-landing-component.module.css';

const rubriqueLanding: React.FC<RubriqueLandingProps> = React.memo(({ news, ...rest }: RubriqueLandingProps) => {
    const actualiteId = getDefault(getNestedReferenceId(news), '');
    return (
        <React.Fragment>
            <div className={`${styles.root}`}>
                <TitleBlocContainer {...rest} />
            </div>

            <div className={`${styles.newsContainer}`}>
                {actualiteId ? <Actualite id={actualiteId} /> : <div>&nbsp;</div>}
            </div>
        </React.Fragment>
    );
});

export const RubriqueLanding = enhancer(rubriqueLanding);
