import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';

export const CarrouselLoader: React.FC = React.memo(() => {
    return (
        <React.Fragment>
            <Placeholder style={{ maxWidth: '100%', textAlign: 'center' }}>
                <Placeholder.Header>
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
        </React.Fragment>
    );
});
