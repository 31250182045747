import React from 'react';
import { Link } from 'react-router-dom';

import { useRouter } from 'modules/shared/hooks';
import { createPathId, removePathId, createPath, concatUrl } from 'modules/shared/utils';
import { readCMSValue, getDefault } from 'modules/shared-cms/utils';
import { CMSImageContainer } from 'modules/shared-cms/components';

import { ThumbProps } from './thumb-type';

import styles from './thumb-component.module.css';

export const Thumb: React.FC<ThumbProps> = React.memo(
    ({ id, data, style, legendStyle, useBrandId, placeholderClassName }: ThumbProps) => {
        const {
            pageTitle,
            thumbTitle,
            thumbSubTitle,
            thumbSecondarySubTitle,
            thumbImage,
            logo,
            urlDirectory,
            brandId,
        } = data;
        let customStyle = {};
        if (logo) {
            const logoValues = readCMSValue(logo);
            if (logoValues && logoValues.length > 0) {
                const logoUrl = logoValues[0].url;
                customStyle = {
                    background: `center / 70% no-repeat url(${logoUrl})`,
                };
            }
        }

        const unwrappedThumbSecondarySubTitle = readCMSValue(thumbSecondarySubTitle);

        const route = useRouter();
        if (id) {
            const firstPart = !urlDirectory || !urlDirectory.isRoot ? removePathId(route.match.url) : '/';
            const secondPart = urlDirectory ? urlDirectory.path : '';

            const bName = createPath(getDefault(readCMSValue(pageTitle), ''));
            const bId = getDefault(useBrandId ? readCMSValue(brandId) : readCMSValue(id), '');
            const thirdPart = createPathId(bName, bId);

            let link = concatUrl([firstPart, secondPart, thirdPart]);
            if (useBrandId && thirdPart.startsWith('&')) {
                // On utilise useBrandId pour limiter l'impact aux pages
                // sign-page (composant liste des marques MID)
                // et creators-page (composant listes des createurs).
                // Donc si une marque MID ou un createur de robe commence par '&'
                // on concatene directement avec un '/'.
                const firstAndSecond = concatUrl([firstPart, secondPart]);
                link = `${firstAndSecond}${!firstAndSecond.endsWith('/') ? '/' : ''}${thirdPart}`;
            }

            return (
                <React.Fragment>
                    <div className={styles.thumbContainer} style={style}>
                        <Link to={link}>
                            <div className={styles.thumbImgContainer}>
                                <CMSImageContainer
                                    picture={thumbImage}
                                    placeholderClassName={placeholderClassName}
                                    style={{ width: '100%' }}
                                />
                            </div>
                            <div className={styles.thumbLegendContainer} style={legendStyle}>
                                <div className={styles.thumbTitle}>{readCMSValue(thumbTitle)}</div>
                                <div className={styles.thumbSubTitle}>{readCMSValue(thumbSubTitle)}</div>
                                {unwrappedThumbSecondarySubTitle && (
                                    <div className={styles.thumbSecondarySubTitle}>
                                        <h5>{unwrappedThumbSecondarySubTitle}</h5>
                                    </div>
                                )}
                            </div>
                        </Link>
                    </div>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <div className={styles.thumbContainer} style={style}>
                    <div className={styles.thumbImgContainer} style={customStyle}>
                        <div className={styles.thumbImage}>
                            <CMSImageContainer picture={thumbImage} style={{ width: '100%' }} />
                        </div>
                    </div>
                    <div className={styles.thumbLegendContainer}>
                        <div className={styles.thumbTitle}>{readCMSValue(thumbTitle)}</div>
                        <div className={styles.thumbSubTitle}>{readCMSValue(thumbSubTitle)}</div>
                        {unwrappedThumbSecondarySubTitle && (
                            <div className={styles.thumbSecondarySubTitle}>
                                <h5>{unwrappedThumbSecondarySubTitle}</h5>
                            </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    },
);
