import React from 'react';

import { getDefault, readCMSValue } from 'modules/shared-cms/utils';
import { getNestedReferenceId } from 'modules/shared-cms-advanced/utils';

import { Description } from '../../components';

import { LandingRubriqueExpanderProps } from './landing-rubrique-expander-type';

import enhancer from './landing-rubrique-expander-enhancer';
import { Introduction, Actualite } from '../../enhanced-blocs';

import styles from './landing-rubrique-expander-component.module.css';

const landingRubriqueExpander: React.FC<LandingRubriqueExpanderProps> = React.memo(
    ({ intro, actualite, text }: LandingRubriqueExpanderProps) => {
        const {
            introId = getDefault(getNestedReferenceId(intro), ''),
            actualiteId = getDefault(getNestedReferenceId(actualite), ''),
            description = getDefault(readCMSValue(text), ''),
        } = {};
        return (
            <React.Fragment>
                {/* <ExpanderBloc titles={data.element} /> */}

                {introId && <Introduction id={introId} />}

                {description && (
                    <div className={styles.description}>
                        <Description small text={description} />
                    </div>
                )}
                <div className={`${styles.newsContainer}`}>{actualiteId && <Actualite id={actualiteId} />}</div>
            </React.Fragment>
        );
    },
);

export const LandingRubriqueExpander = enhancer(landingRubriqueExpander);
