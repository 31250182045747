import React, { useState } from 'react';
import { Select, DropdownProps } from 'vendors/semantic-ui';

import { Carousel } from 'modules/shared-ui-advanced/components';
import { getDefault, readCMSValue, buildCMSStyles } from 'modules/shared-cms/utils';
import { TextColorProps, TitleProps } from 'modules/shared-cms/cms-base-type';

import { ModelBloc } from '../model-bloc';

import { CarouselFilterBlocProps } from './carousel-filter-bloc-type';

import styles from './carousel-filter-bloc-component.module.css';

const Content: React.FC<TextColorProps> = props => {
    const { children } = props;
    return (
        <div className={styles.content} style={buildCMSStyles<TextColorProps>(props)}>
            {children}
        </div>
    );
};

export const CarouselFilterBloc: React.FC<CarouselFilterBlocProps> = React.memo(
    ({
        title: wrappedTitle,
        fontSizeTitle: wrappedFontSizeTitle,
        textColor: wrappedTextColor,
        items: wrappedItems,
        filterValues,
    }: CarouselFilterBlocProps) => {
        const {
            title = getDefault(readCMSValue(wrappedTitle), ''),
            fontSizeTitle = getDefault(readCMSValue(wrappedFontSizeTitle), 26),
            textColor = readCMSValue(wrappedTextColor),
            items = getDefault(readCMSValue(wrappedItems), []),
        } = {};

        const currentDate = new Date(Date.now());
        const month = currentDate.getMonth();
        const [selected, setSelected] = useState(filterValues[month].value);

        const handleSelected: (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => void = (
            event: React.SyntheticEvent<HTMLElement, Event>,
            data: DropdownProps,
        ) => {
            if (event && data.value) {
                setSelected(data.value as string);
            }
        };

        return (
            <div className={styles.wrapper}>
                <Content textColor={textColor}>
                    <div className={styles.textContent}>
                        <div className={styles.title} style={buildCMSStyles<TitleProps>({ title, fontSizeTitle })}>
                            {title}
                        </div>
                        <Select
                            defaultValue={filterValues[month].value}
                            className={` ${styles.selector} custom-selector`}
                            placeholder="Choisir un mois"
                            options={filterValues}
                            onChange={handleSelected}
                            style={{ fontSize: '1.6rem' }}
                        />
                    </div>
                    <div className={styles.carousel}>
                        <Carousel cards={3} auto>
                            {items
                                .slice()
                                .filter(item => {
                                    return selected ? item.month.includes(selected as string) : true;
                                })
                                .map((item, index) => {
                                    return (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <React.Fragment key={index}>
                                            <ModelBloc className="modelBloc" {...item} />
                                        </React.Fragment>
                                    );
                                })}
                        </Carousel>
                    </div>
                </Content>
            </div>
        );
    },
);
