import React from 'react';
import { DocumentNode } from 'graphql';

import { Omit } from 'modules/shared/types/type-utils';

import { CMSQuery } from 'modules/shared-cms/hocs/components';

const mapParams = (
    filterToFormat: { name?: string; filter: string },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    variables: any,
    filterParams?: { key: string; value: string | string[] }[],
): void => {
    const variablesForUpdate = variables;
    if (filterParams && filterParams.length > 0) {
        let formattedFilter = filterToFormat.filter;
        filterParams.forEach(param => {
            const { key, value } = param;
            if (value) {
                if (typeof value === 'string') {
                    formattedFilter = formattedFilter.replace(`$${key}`, value);
                } else {
                    formattedFilter = formattedFilter.replace(`$${key}`, `"${value.join('","')}"`);
                }
            }
        });
        variablesForUpdate[filterToFormat.name || 'filter'] = formattedFilter;
    }
};

export interface WithFetchDatasByParamsFromCMSInputProps {
    filterParams?: { key: string; value: string | string[] }[];
    onLoadingStateChange?: (state: boolean) => void;
}

export function WithFetchDatasByParamsFromCMS<OutputProps>(
    Loader: React.ComponentType,
    query: DocumentNode,
    queryName?: string,
    filters?: { name?: string; filter: string }[],
    outputField?: keyof OutputProps,
    bypassNotFound: boolean = false,
    // TODO: a revoir
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getParams?: (props: any) => { key: string; value: string }[],
) {
    return <P extends OutputProps>(Component: React.ComponentType<P>) => {
        type WrapperType = Omit<P, keyof OutputProps> & WithFetchDatasByParamsFromCMSInputProps;
        const WithFetchDatasByParamsFromCMSWrapper: React.FC<WrapperType> = ({
            filterParams,
            onLoadingStateChange,
            ...rest
        }: WrapperType) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const variables: any = {};
            if (filters && filters.length > 0) {
                filters.forEach(f => {
                    const formatedFilter = f;
                    if (formatedFilter) {
                        mapParams(formatedFilter, variables, filterParams);

                        if (getParams) {
                            mapParams(formatedFilter, variables, getParams(rest));
                        }

                        const filterName = formatedFilter.name ? formatedFilter.name : 'filter';
                        if (!variables[filterName]) {
                            variables[filterName] = formatedFilter.filter;
                        }
                    }
                });
            }

            return (
                <CMSQuery
                    Loader={Loader}
                    query={query}
                    variables={variables}
                    bypassNotFound={bypassNotFound}
                    onLoadingStateChange={onLoadingStateChange}
                    readPayload={payload => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        let innerData: any;
                        if (queryName) {
                            innerData =
                                payload && payload[queryName] && payload[queryName] && payload[queryName].length > 0
                                    ? payload[queryName][0].data
                                    : null;
                            if (innerData && Object.keys(payload).length > 1) {
                                Object.keys(payload).forEach(k => {
                                    if (k === queryName) {
                                        return;
                                    }

                                    innerData[k] = payload[k];
                                });
                            }
                        } else {
                            innerData = payload;
                        }

                        if (!bypassNotFound && !innerData) {
                            return innerData;
                        }

                        if (outputField) {
                            return {
                                [outputField]: innerData,
                            };
                        }
                        return innerData;
                    }}
                    render={data => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        return <Component {...(rest as any)} {...data} />;
                    }}
                />
            );
        };

        return WithFetchDatasByParamsFromCMSWrapper;
    };
}
