import React from 'react';

import { CtaImage } from 'modules/shared-ui/components';

import { LogoProps } from '.';

import styles from './logo-component.module.css';

export const LogoContainer: React.FC<LogoProps> = ({ logo, logoLink, className }: LogoProps) => {
    return (
        <div className={styles.logoContainer + className ? ` ${className}` : ''}>
            <CtaImage link={logoLink} target="blank" picture={logo} />
        </div>
    );
};
