import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';
import { readCMSValue, getDefaultUrlPicture, getDefault } from 'modules/shared-cms/utils';

import { CtaButton, ClickableContainer, HtmlContainer } from 'modules/shared-ui/components';

import { ArticleProps } from './article-bloc-type';

import styles from './article-bloc-component.module.css';

const ArticleContainer: React.FC = props => {
    const { children } = props;
    return <div className={styles.articleCard}>{children}</div>;
};

export const ArticleBlocLoader: React.FC = () => {
    return (
        <ArticleContainer>
            <Placeholder style={{ height: 400 }} fluid>
                <Placeholder.Image />
            </Placeholder>
        </ArticleContainer>
    );
};

export const Article: React.FC<ArticleProps> = React.memo(
    ({
        title: wrappedTitle,
        subTitle: wrappedSubTitle,
        ctaText: wrappedCtaText,
        ctaLink: wrappedCtaLink,
        ctaStyle: wrappedCtaStyle,
        picture: wrappedPicture,
        targetBlank: wrappedTargetBlank,
    }: ArticleProps) => {
        const {
            title = readCMSValue(wrappedTitle),
            subTitle = readCMSValue(wrappedSubTitle),
            ctaText = readCMSValue(wrappedCtaText),
            ctaLink = readCMSValue(wrappedCtaLink),
            ctaStyle = readCMSValue(wrappedCtaStyle),
            picture = getDefaultUrlPicture(wrappedPicture),
            targetBlank = getDefault(readCMSValue(wrappedTargetBlank), false),
        } = {};

        return (
            <React.Fragment>
                <ArticleContainer>
                    <div className={styles.card}>
                        <div>
                            {picture && ctaLink && (
                                <ClickableContainer link={ctaLink}>
                                    <img src={picture} alt="" className={styles.articleCardImage} />
                                </ClickableContainer>
                            )}
                            {title && (
                                <div className={styles.articleCardHeader}>
                                    <HtmlContainer htmlContent={title} />
                                </div>
                            )}
                            {subTitle && (
                                <div className={styles.articleCardSubHeader}>
                                    <HtmlContainer htmlContent={subTitle} />
                                </div>
                            )}
                        </div>
                        <div className={styles.articleCardButtonCTA}>
                            {ctaText && (
                                <CtaButton
                                    ctaText={ctaText}
                                    ctaLink={ctaLink}
                                    ctaStyle={ctaStyle}
                                    target={targetBlank ? '_blank' : '_self'}
                                />
                            )}
                        </div>
                    </div>
                </ArticleContainer>
            </React.Fragment>
        );
    },
);
