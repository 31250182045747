import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindCarrouselVisuelsContent($id: String!) {
    findCarrouselVisuelsContent(id: $id) {
        data {
            superTitle { iv }
            superTitleFontSize { iv }
            title { iv }
            titleFontSize { iv }
            subTitle { iv }
            subTitleFontSize { iv }
            backgroundColor { iv }
            color { iv }
            ctaLink { iv }
            ctaText { iv }
            ctaStyle { iv }
            altMode { iv }
            items {
                iv {
                    title
                    subTitle
                    backgroundColor
                    color
                    justification
                    picture {
                        url
                        fileSize
                    }
                    link
                }
            }
        }
    }
}
`,
]);
