import * as React from 'react';

import { getDefault } from 'modules/shared-cms/utils';
import { getNestedReferenceId } from 'modules/shared-cms-advanced/utils';

import { Actualite, Introduction } from '../../enhanced-blocs';

import { HoveredImagesListBloc, SelectionBloc } from '../../components';

import { SousRubriqueLandingProps } from './sous-rubrique-landing-type';

import enhancer from './sous-rubrique-landing-enhancer';

import styles from './sous-rubrique-landing-component.module.css';

const sousRubriqueLanding: React.FC<SousRubriqueLandingProps> = React.memo(
    ({
        intro,
        news,
        listeImageTitle,
        listeImageColor,
        listeImage,
        selectionTitle,
        selectionTextColor,
        selectionColor,
        selectionItems,
    }: SousRubriqueLandingProps) => {
        const introId = getDefault(getNestedReferenceId(intro), '');
        const actualiteId = getDefault(getNestedReferenceId(news), '');

        return (
            <React.Fragment>
                {introId ? <Introduction id={introId} /> : <div>&nbsp;</div>}

                <div className={`${styles.root}`}>
                    <div className={`${styles.newsContainer}`}>
                        {actualiteId ? <Actualite id={actualiteId} /> : <div>&nbsp;</div>}
                    </div>
                    <SelectionBloc
                        title={selectionTitle}
                        color={selectionColor}
                        textColor={selectionTextColor}
                        items={selectionItems}
                    />{' '}
                    <HoveredImagesListBloc
                        title={listeImageTitle}
                        color={listeImageColor}
                        list={listeImage}
                        style={{ maxWidth: '200px' }}
                    />
                </div>
            </React.Fragment>
        );
    },
);

export const SousRubriqueLanding = enhancer(sousRubriqueLanding);
