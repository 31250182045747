import React from 'react';

import { TitleBloc } from '../../base/title-bloc';

import { TitleBlocContainerProps } from './title-bloc-container-type';

export const TitleBlocContainer: React.FC<TitleBlocContainerProps> = React.memo(
    ({
        titleBlocTitle,
        titleBlocSubTitle,
        titleBlocFontSizeTitle,
        titleBlocFontSizeSubTitle,
        titleBlocTagline,
        titleBlocWidth,
        titleBlocColor,
        titleBlocTextColor,
        titleBlocCtaLink,
        titleBlocCtaText,
        titleBlocCtaStyle,
        titleBlocCtaLink2,
        titleBlocCtaText2,
        titleBlocCtaStyle2,
        titleBlocPicture,
        titleBlocHorizontalPosition,
        titleBlocVerticalPosition,
        titleBlocJustification,
        blockResponsive,
        fullWidth,
    }: TitleBlocContainerProps) => {
        return (
            <TitleBloc
                title={titleBlocTitle}
                subTitle={titleBlocSubTitle}
                fontSizeTitle={titleBlocFontSizeTitle}
                fontSizeSubTitle={titleBlocFontSizeSubTitle}
                tagline={titleBlocTagline}
                width={titleBlocWidth}
                textColor={titleBlocTextColor}
                picture={titleBlocPicture}
                color={titleBlocColor}
                ctaLink={titleBlocCtaLink}
                ctaText={titleBlocCtaText}
                ctaStyle={titleBlocCtaStyle}
                ctaLink2={titleBlocCtaLink2}
                ctaText2={titleBlocCtaText2}
                ctaStyle2={titleBlocCtaStyle2}
                horizontalPosition={titleBlocHorizontalPosition}
                verticalPosition={titleBlocVerticalPosition}
                justification={titleBlocJustification}
                blockResponsive={blockResponsive}
                fullWidth={fullWidth}
            />
        );
    },
);
