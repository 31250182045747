import * as React from 'react';

import { readCMSValue, getDefault } from 'modules/shared-cms/utils';

import { MentionContainer } from '../../components';

import { MentionProps } from './mention-type';

import enhancer from './mention-enhancer';

import styles from './mention-component.module.css';

const mention: React.FC<MentionProps> = React.memo((props: MentionProps) => {
    const mentionProps = {
        mention: getDefault(readCMSValue(props.text), ''),
        mentionFontSize: getDefault(readCMSValue(props.fontSize), 10),
        mentionTextColor: getDefault(readCMSValue(props.textColor), '#000000'),
    };

    return (
        <React.Fragment>
            {mentionProps.mention !== '' && (
                <div className={`${styles.mentionContainer}`}>
                    <MentionContainer {...mentionProps} />
                </div>
            )}
        </React.Fragment>
    );
});

export const Mention = enhancer(mention);
