import * as React from 'react';
import { Link } from 'react-router-dom';

import { CMSImageContainer } from 'modules/shared-cms/components';
import { extractCMSOrDefault } from 'modules/shared-cms/utils';
import { LogosBlocProps } from './logos-bloc-types';

import styles from './logos-bloc-component.module.css';

export const LogosBloc: React.FC<LogosBlocProps> = ({ logos }) => {
    return (
        <>
            {logos.length > 0 && (
                <div className={styles.root}>
                    {logos.map((logo, index) => {
                        const link = extractCMSOrDefault(logo.pictureLink, '#');
                        const externalUrl = !!(link.startsWith('http') || link.startsWith('www'));
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={`logo-bloc-${index}`} className={styles.item}>
                                {externalUrl ? (
                                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                                    <a href={link} target="_blank" rel="noopener noreferrer">
                                        <div className={styles.imgContainer}>
                                            <CMSImageContainer picture={logo.picture} />
                                        </div>
                                    </a>
                                ) : (
                                    <Link to={link}>
                                        <div className={styles.imgContainer}>
                                            <CMSImageContainer picture={logo.picture} />
                                        </div>
                                    </Link>
                                )}
                                <div className={styles.text}>
                                    <span>{extractCMSOrDefault(logo.text, '')}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </>
    );
};
