import React from 'react';

import { TitleBlocLoader } from '../../components';

import styles from './introduction-component.module.css';

export const IntroductionLoader: React.FC = React.memo(() => {
    return (
        <React.Fragment>
            <div className={`${styles.root}`}>
                <TitleBlocLoader />
            </div>
        </React.Fragment>
    );
});
