import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindNosMariesContent($id: String!) {
    findNosMariesContent(id: $id) {
    data {
      pageTitle {
        iv
      }
      pageName {
        iv
      }

      titleBlocTitle {
        iv
      }
      titleBlocSubTitle {
        iv
      }
      titleBlocWidth {
        iv
      }
      titleBlocTagline {
        iv
      }
      titleBlocTextColor {
        iv
      }
      titleBlocColor {
        iv
      }
      titleBlocCtaLink {
        iv
      }
      titleBlocCtaText {
        iv
      }
      titleBlocCtaStyle {
        iv
      }
      titleBlocCtaLink2 {
        iv
      }
      titleBlocCtaText2 {
        iv
      }
      titleBlocCtaStyle2 {
        iv
      }
      titleBlocPicture {
        iv {
          url
          fileSize
        }
      }
      titleBlocHorizontalPosition {
        iv
      }
      titleBlocVerticalPosition {
        iv
      }
      titleBlocJustification {
        iv
      }

      description {
        iv
      }

      customers{
        iv {
            thumb{
                url
                fileSize
            }
            title
            legend
            images{
                url
                fileSize
            }
        }
      }

      mention {
        iv
      }

      seoDescription {
        iv
      }
      seoKeywords {
        iv
      }
      seoThumbnail {
        iv {
          url
        }
      }

    }
  }
}
`,
]);
