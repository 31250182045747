import React from 'react';
import { Responsive } from 'vendors/semantic-ui';

import { configUI } from 'config';

import { HtmlContainer } from 'modules/shared-ui/components';
import { readCMSValue, getDefault } from 'modules/shared-cms/utils';
import { CMSImageContainer } from 'modules/shared-cms/components';

import { ImagesBlocProps, ImageBlocProps, ImageBlocType } from './images-bloc-type';

import styles from './images-bloc-component.module.css';

const buildImage = (img: ImageBlocType, hideFilter?: boolean): JSX.Element => {
    const { link, image, title, text, color, textColor } = img;
    return (
        <React.Fragment>
            {link ? (
                <div className={styles.container}>
                    <a href={readCMSValue(link)} target="_blank" rel="noopener noreferrer">
                        <CMSImageContainer picture={image} style={{ width: '100%' }}>
                            <div className={styles.card}>
                                <div
                                    className={`${styles.cardContent} ${!hideFilter ? styles.filter : ''}`}
                                    style={
                                        color || textColor
                                            ? {
                                                  backgroundColor: readCMSValue(color),
                                                  color: readCMSValue(textColor),
                                              }
                                            : {}
                                    }
                                >
                                    <div>
                                        <div className={styles.title}>
                                            <HtmlContainer htmlContent={getDefault(readCMSValue(title), '')} />
                                        </div>
                                        <div className={styles.description}>
                                            <HtmlContainer htmlContent={getDefault(readCMSValue(text), '')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CMSImageContainer>
                    </a>
                </div>
            ) : (
                <React.Fragment>
                    <div className={styles.container}>
                        <CMSImageContainer picture={image} style={{ width: '100%' }} />
                        <div className={styles.captionText}>
                            <span>
                                <HtmlContainer htmlContent={getDefault(readCMSValue(text), '')} />
                            </span>
                            <span>
                                <HtmlContainer htmlContent={getDefault(readCMSValue(text), '')} />
                            </span>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const buildImageLine = (imageLine: ImageBlocProps, hideFilter?: boolean): JSX.Element => {
    const {
        image1,
        image2,
        text1,
        text2,
        link1,
        link2,
        title1,
        title2,
        color1,
        color2,
        textColor1,
        textColor2,
    } = imageLine;
    const hasTwoImages = image1 && image1.length > 0 && image2 && image2.length > 0;
    return (
        <React.Fragment>
            <Responsive className={styles.line} minWidth={configUI.responsive.tablet.minWidth}>
                <div className={styles.imageLineContainer}>
                    {image1 && image1.length > 0 && (
                        <div className={`${styles.imageContainer} ${hasTwoImages ? styles.halfWidth : ''}`}>
                            {buildImage(
                                {
                                    image: image1,
                                    text: text1,
                                    link: link1,
                                    title: title1,
                                    color: color1,
                                    textColor: textColor1,
                                },
                                hideFilter,
                            )}
                        </div>
                    )}
                    {image2 && image2.length > 0 && (
                        <div className={`${styles.imageContainer} ${hasTwoImages ? styles.halfWidth : ''}`}>
                            {buildImage(
                                {
                                    image: image2,
                                    text: text2,
                                    link: link2,
                                    title: title2,
                                    color: color2,
                                    textColor: textColor2,
                                },
                                hideFilter,
                            )}
                        </div>
                    )}
                </div>
            </Responsive>
            <Responsive className={styles.line} maxWidth={configUI.responsive.mobile.maxWidth}>
                <div className={styles.imageLineContainer}>
                    {image1 && image1.length > 0 && (
                        <div className={`${styles.imageContainer} ${hasTwoImages ? styles.halfWidth : ''}`}>
                            {buildImage(
                                {
                                    image: image1,
                                    text: text1,
                                    link: link1,
                                    title: title1,
                                    color: color1,
                                    textColor: textColor1,
                                },
                                hideFilter,
                            )}
                        </div>
                    )}
                </div>

                <div className={styles.imageLineContainer}>
                    {image2 && image2.length > 0 && (
                        <div className={`${styles.imageContainer} ${hasTwoImages ? styles.halfWidth : ''}`}>
                            {buildImage(
                                {
                                    image: image2,
                                    text: text2,
                                    link: link2,
                                    title: title2,
                                    color: color2,
                                    textColor: textColor2,
                                },
                                hideFilter,
                            )}
                        </div>
                    )}
                </div>
            </Responsive>
        </React.Fragment>
    );
};

export const ImagesBloc: React.FC<ImagesBlocProps> = React.memo(({ images, hideFilters = false }: ImagesBlocProps) => {
    const imagesValue = readCMSValue(images);
    return (
        <React.Fragment>
            {imagesValue &&
                imagesValue.length > 0 &&
                imagesValue.map((imageLine, key) => {
                    // eslint-disable-next-line react/no-array-index-key
                    return <React.Fragment key={key}>{buildImageLine(imageLine, hideFilters)}</React.Fragment>;
                })}
        </React.Fragment>
    );
});
