import * as React from 'react';
import { getDefault, readCMSValue, getDefaultUrlPicture, buildCMSStyles } from 'modules/shared-cms/utils';
import { TitleProps } from 'modules/shared-cms/cms-base-type';
import { StaggeredImageBlocProps } from './staggered-image-bloc-type';

import styles from './staggered-image-bloc-component.module.css';

export const StaggeredImageBloc: React.FC<StaggeredImageBlocProps> = React.memo(
    ({ url, image1, image2, image3, title }: StaggeredImageBlocProps) => {
        const img1 = getDefaultUrlPicture(image1);
        const img2 = getDefaultUrlPicture(image2);
        const img3 = getDefaultUrlPicture(image3);

        return (
            <React.Fragment>
                {img1 && img2 && (
                    <a href={getDefault(readCMSValue(url), '')}>
                        <div className={styles.wrapper}>
                            <div
                                className={styles.title}
                                style={buildCMSStyles<TitleProps>({
                                    title,
                                    fontSizeTitle: 26,
                                })}
                            >
                                {readCMSValue(title)}
                            </div>
                            <div className={styles.imageContainer}>
                                {img1 && <img src={img1} alt="" className={styles.image1} />}
                                {img2 && <img src={img2} alt="" className={styles.image2} />}
                                {img3 && <img src={img3} alt="" className={styles.image3} />}
                            </div>
                        </div>
                    </a>
                )}
            </React.Fragment>
        );
    },
);
