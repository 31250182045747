import * as React from 'react';
import { TitleProps, TextColorProps, SubTitleProps } from 'modules/shared-cms/cms-base-type';
import { buildCMSStyles, getDefault, readCMSValue } from 'modules/shared-cms/utils';
import { CtaButton, HtmlContainer } from 'modules/shared-ui/components';

import { configUI } from 'config';
import { Responsive } from 'vendors/semantic-ui';
import styles from './legend-bloc-component.module.css';

import { LegendBlocProps } from '.';

export const LegendBloc: React.FC<LegendBlocProps> = React.memo((props: LegendBlocProps) => {
    const {
        title = getDefault(readCMSValue(props.title), ''),
        subTitle = getDefault(readCMSValue(props.subTitle), ''),
        tagline = getDefault(readCMSValue(props.tagline), ''),
        fontSizeTitle = getDefault(readCMSValue(props.fontSizeTitle), 32),
        fontSizeSubTitle = getDefault(readCMSValue(props.fontSizeSubTitle), 18),
        textColor = getDefault(readCMSValue(props.textColor), '#000000'),
        titleClassName = `${styles.title} ${props.titleClassName || ''} ${
            !props.disableColorChange ? styles.colorChange : ''
        }`,
        subTitleClassName = `${styles.text} ${props.subTitleClassName || ''} ${
            !props.disableColorChange ? styles.colorChange : ''
        }`,
        tagLineClassName = `${styles.text} ${props.tagLineClassName || ''} ${
            !props.disableColorChange ? styles.colorChange : ''
        }`,
        ctaText = readCMSValue(props.ctaText),
        ctaLink = readCMSValue(props.ctaLink),
        ctaStyle = readCMSValue(props.ctaStyle),
        ctaText2 = readCMSValue(props.ctaText2),
        ctaLink2 = readCMSValue(props.ctaLink2),
        ctaStyle2 = readCMSValue(props.ctaStyle2),
    } = {};

    const titleStyle = buildCMSStyles<TitleProps & TextColorProps>({
        title,
        fontSizeTitle,
        textColor,
    });

    const subTitleStyle = buildCMSStyles<SubTitleProps & TextColorProps>({
        subTitle,
        fontSizeSubTitle,
        textColor,
    });

    const tagLineStyle = {
        ...titleStyle,
        fontSize: 16,
    };

    return (
        <React.Fragment>
            <div className={styles.container}>
                {(title || tagline || subTitle) && (
                    <React.Fragment>
                        {tagline !== '' && (
                            <div className={tagLineClassName} style={tagLineStyle}>
                                {tagline}
                            </div>
                        )}
                        {title !== '' && (
                            <div className={titleClassName} style={titleStyle}>
                                <HtmlContainer
                                    node="h1"
                                    htmlContent={title}
                                    className={titleClassName}
                                    style={titleStyle}
                                />
                            </div>
                        )}
                        {subTitle !== '' && (
                            <div className={subTitleClassName} style={subTitleStyle}>
                                <HtmlContainer htmlContent={subTitle} />
                            </div>
                        )}
                    </React.Fragment>
                )}
                {(ctaText || ctaText2) && (
                    <div className={styles.ctaButtonContainer}>
                        <Responsive as={React.Fragment} minWidth={configUI.responsive.computer.minWidth}>
                            {ctaText ? (
                                <div className={`${styles.ctaButton} ${styles.leftCta}`}>
                                    <CtaButton
                                        fullWidth
                                        forceHover={false}
                                        ctaText={ctaText}
                                        ctaLink={ctaLink}
                                        ctaStyle={ctaStyle}
                                    />
                                </div>
                            ) : (
                                <div className={styles.ctaButton}>&nbsp;</div>
                            )}
                        </Responsive>
                        <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                            {ctaText && (
                                <div className={`${styles.ctaButton} ${styles.leftCta}`}>
                                    <CtaButton
                                        fullWidth
                                        forceHover={false}
                                        ctaText={ctaText}
                                        ctaLink={ctaLink}
                                        ctaStyle="normal"
                                    />
                                </div>
                            )}
                        </Responsive>
                        {ctaText2 && (
                            <div className={`${styles.ctaButton} ${styles.rightCta}`}>
                                <CtaButton
                                    fullWidth
                                    forceHover={false}
                                    ctaText={ctaText2}
                                    ctaLink={ctaLink2}
                                    ctaStyle={ctaStyle2}
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
});
