import gql from 'graphql-tag';

export const QUERY = gql([
    `
query findRubriqueLandingExpanderContent($id: String!) {
  findRubriqueLandingExpanderContent(id: $id) {
    data {
      pageTitle {
        iv
      }
      seoDescription {
        iv
      }
      seoKeywords {
        iv
      }
      seoThumbnail {
        iv {
          url
        }
      }

      intro {
        iv{id}
      }

      actualite {
        iv{id}
      }
      text {
        iv
      }
      element {
        iv {
          tagline
          ctaText
          ctaLink
          ctaStyle
          ctaText2
          ctaLink2
          ctaStyle2
          title
          fontSizeTitle
          fontSizeSubTitle
          subTitle
          textColor
          width
          justifyProps
          color
          verticalPosition
          horizontalPosition
          picture {
            url
          }
        }
      }
    }
  }
}
`,
]);
