import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindTendanceContent($id: String!)
{
  findTendanceContent(id: $id) {
    data {

      trendInverted {iv}
      trendTitle {iv}
      trendFontSizeTitle {iv}
      trendSubTitle {iv}
      trendFontSizeSubTitle {iv}
      trendTextColor {iv}
      trendColor {iv}
      trendMainPicture {
        iv {
          url
          fileSize
        }
      }
      trendMainPictureLink {iv}
      trendCtaLink {iv}
      trendCtaText {iv}
      trendCtaStyle {iv}
      trendPictures {
        iv {
          picture {
            url
            fileSize
          }
          pictureLink
          title
        }
      }
    }
  }
}`,
]);
