import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';

import { readCMSValue, getDefaultUrlPicture, getDefault } from 'modules/shared-cms/utils';
import { useHover } from 'modules/shared/hooks';
import { CtaButton, ClickableContainer } from 'modules/shared-ui/components';

import styles from './news-component.module.css';

import { NewsProps } from './news-type';

const NewsContainer: React.FC = props => {
    const { children } = props;
    return <div className={`${styles.news}`}>{children}</div>;
};

export const News: React.FC<NewsProps> = ({
    title: wrappedTitle,
    subTitle: wrappedSubTitle,
    picture: wrappedPicture,
    ctaLink: wrappedCtaLink,
    ctaText: wrappedCtaText,
    ctaStyle: wrappedCtaStyle,
    textColor: wrappedTextColor,
    target = '_self',
    alternativeLayout,
    hideFilter,
}: NewsProps) => {
    const { hovered, bind } = useHover();

    const {
        title = readCMSValue(wrappedTitle),
        subTitle = readCMSValue(wrappedSubTitle),
        textColor = getDefault(readCMSValue(wrappedTextColor), 'white'),
        picture = getDefaultUrlPicture(wrappedPicture),
        ctaLink = readCMSValue(wrappedCtaLink),
        ctaText = readCMSValue(wrappedCtaText),
        ctaStyle = readCMSValue(wrappedCtaStyle),
        alt = alternativeLayout || false,
    } = {};

    return (
        <ClickableContainer target={target} link={ctaLink} onHover={bind}>
            <NewsContainer>
                {picture && (
                    <div className={`${styles.imgContainer} ${alt ? styles.alt : ''}`}>
                        <img className={`${styles.image} ${alt ? styles.alt : ''}`} src={picture} alt="" />
                        {alt && title && (
                            <React.Fragment>
                                {!hideFilter ? (
                                    <div className={styles.filter}>
                                        <span className={`${styles.title} ${styles.alt}`} style={{ color: textColor }}>
                                            {title}
                                        </span>
                                    </div>
                                ) : (
                                    <div className={styles.titleContainer}>
                                        <span className={`${styles.title} ${styles.alt}`} style={{ color: textColor }}>
                                            {title}
                                        </span>
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}

                <div className={styles.textContent}>
                    <div>
                        {!alt && (
                            <div className={`${styles.title}`}>
                                <h1>{title}</h1>
                            </div>
                        )}

                        {subTitle ? (
                            <div className={styles.subTitle}>
                                <p>{subTitle}</p>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>

                    <div className={styles.button}>
                        {ctaLink && ctaText && (
                            <CtaButton target={target} forceHover={!!hovered} ctaText={ctaText} ctaStyle={ctaStyle} />
                        )}
                    </div>
                </div>
            </NewsContainer>
        </ClickableContainer>
    );
};

export const NewsLoader: React.FC = () => {
    return (
        <NewsContainer>
            <Placeholder style={{ height: 350 }} fluid>
                <Placeholder.Image className={`${styles.image}`} />
            </Placeholder>

            <Placeholder className="full-width">
                <Placeholder.Paragraph>
                    <Placeholder.Line length="short" className={`${styles.title}`} />
                </Placeholder.Paragraph>

                <Placeholder.Paragraph>
                    <Placeholder.Line length="very long" className={`${styles.title}`} />
                    <Placeholder.Line length="very long" className={`${styles.title}`} />
                    <Placeholder.Line length="very long" className={`${styles.title}`} />
                </Placeholder.Paragraph>
            </Placeholder>

            <Placeholder style={{ height: 50, width: 150 }}>
                <Placeholder.Image className={`${styles.button}`} />
            </Placeholder>
        </NewsContainer>
    );
};

export const NewsListContainer: React.FC = props => {
    const { children } = props;
    return (
        <div className={`${styles.newsContainer}`}>
            {React.Children.toArray(children).map((child, key) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={key}>{child}</div>
                );
            })}
        </div>
    );
};
