import React from 'react';

import { readCMSValue } from 'modules/shared-cms/utils';

import { CustomerBloc } from '../../base';
import { CustomerBlocContainerProps } from './customer-bloc-container-type';

export const CustomerBlocContainer: React.FC<CustomerBlocContainerProps> = ({
    customerColor,
    customerItems,
    customerLink,
    customerTitle,
}: CustomerBlocContainerProps) => {
    const items = readCMSValue(customerItems);

    if (!items || items.length === 0) {
        return <React.Fragment />;
    }
    return <CustomerBloc items={customerItems} color={customerColor} link={customerLink} title={customerTitle} />;
};
