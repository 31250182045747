import * as React from 'react';

import { PromotionContainerProps } from '.';
import { PromotionBloc } from '../../base';

export const PromotionContainer: React.FC<PromotionContainerProps> = ({
    promotionSuperTitle,
    promotionTitle,
    promotionFontSizeTitle,
    promotionTitleClassName,
    promotionSubTitle,
    promotionFontSizeSubTitle,
    promotionTextColor,
    promotionCtaText,
    promotionCtaLink,
    promotionCtaStyle,
    promotionBackgroundColor,
    promotionsItems,
    children,
}: React.PropsWithChildren<PromotionContainerProps>) => (
    <PromotionBloc
        superTitle={promotionSuperTitle}
        title={promotionTitle}
        fontSizeTitle={promotionFontSizeTitle}
        titleClassName={promotionTitleClassName}
        subTitle={promotionSubTitle}
        fontSizeSubTitle={promotionFontSizeSubTitle}
        textColor={promotionTextColor}
        ctaText={promotionCtaText}
        ctaLink={promotionCtaLink}
        ctaStyle={promotionCtaStyle}
        color={promotionBackgroundColor}
        items={promotionsItems}
    >
        {children}
    </PromotionBloc>
);
