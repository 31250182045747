import { readCMSValue, getDefault } from 'modules/shared-cms/utils';
import { CMSValueElement } from '../cms-other-type';

export const buildCMSValueList = (
    lists: CMSValueElement[],
    placeholder: string,
    useId?: boolean,
): { key: string; value: string; text: string }[] => {
    return [{ key: '', value: '', text: placeholder }].concat(
        lists
            .slice()
            .filter(l => l && l.data && (useId ? l.id : l.data.value))
            .map(type => {
                const value = getDefault(readCMSValue(useId ? type.id : type.data.value), '');
                return {
                    key: value,
                    value,
                    text: getDefault(readCMSValue(type.data.text), ''),
                };
            }),
    );
};
