import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindPromoContent($id: String!)
{
  findPromoContent(id: $id) {
    data {
      promotionSuperTitle {
        iv
      }
      promotionTitle {
        iv
      }
      promotionSubTitle {
        iv
      }
      promotionColor {
        iv
      }
      promotionCtaLink {
        iv
      }
      promotionCtaText {
        iv
      }
      promotionCtaStyle {
        iv
      }
      promotionTextColor {
        iv
      }
      promotionItems {
        iv {
          title
          subTitle
          color
          link
          picture {
              url
              fileSize
          }
          textColor
          justification
        }
      }
    }
  }
}
`,
]);
