import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindBlocActualiteContent($id: String!)
{
    findBlocActualiteContent(id: $id) {
        data {
            title { iv }
            titleColor { iv }
            titleFontSize { iv }
            subtitle { iv }
            subtitleColor { iv }
            subtitleFontSize { iv }
            tagUnselectedBackgroundColor { iv }
            tagUnselectedColor { iv }
            tagBackgroundColor { iv }
            tagColor { iv }

            news {
                iv {
                    picture { 
                        url
                        fileSize
                    }
                    title
                    titleColor
                    titleFontSize
                    titleAlign
                    subtitle
                    subtitleColor
                    subtitleFontSize
                    subtitleAlign
                    ctaText
                    ctaLink
                    ctaStyle
                    ctaAlign
                    tags
                }
            }
        }
    }
}`,
]);
