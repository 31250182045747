import * as React from 'react';

import { getDefault, readCMSValue, buildCMSStyles } from 'modules/shared-cms/utils';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { TitleProps, TextColorProps, BackgroundColorProps } from 'modules/shared-cms/cms-base-type';
import { HtmlContainer } from 'modules/shared-ui/components';

import { DoubleStaggeredBlocProps } from './double-staggered-bloc-type';

import styles from './double-staggered-bloc-component.module.css';

export const DoubleStaggeredBloc: React.FC<DoubleStaggeredBlocProps> = ({
    text: wpText,
    picture1: wpPicture1,
    picture2: wpPicture2,
    color: wpColor,
}: DoubleStaggeredBlocProps) => {
    const {
        text = getDefault(readCMSValue(wpText), ''),
        picture1 = getDefault(readCMSValue(wpPicture1), []),
        picture2 = getDefault(readCMSValue(wpPicture2), []),
        color = getDefault(readCMSValue(wpColor), '#FFFFFF00'),
    } = {};

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {picture1 && picture2 && (
                    <div className={styles.picture1}>
                        <CMSImageContainer picture={picture1} style={{ width: '100%' }} />
                    </div>
                )}
                {(picture2 || picture1) && (
                    <div className={styles.picture2}>
                        <CMSImageContainer picture={picture2 || picture1} style={{ width: '100%' }} />
                    </div>
                )}
                {text.length > 0 && (
                    <div className={styles.textWrapper} style={buildCMSStyles<BackgroundColorProps>({ color })}>
                        <div
                            className={styles.text}
                            style={buildCMSStyles<TitleProps & TextColorProps>({
                                title: text,
                                fontSizeTitle: 16,
                                textColor: '#121212',
                            })}
                        >
                            <HtmlContainer htmlContent={text} />
                        </div>
                    </div>
                )}
            </div>
            <div
                className={styles.textMobile}
                style={buildCMSStyles<TitleProps & TextColorProps>({
                    title: text,
                    fontSizeTitle: 16,
                    textColor: '#121212',
                })}
            >
                <HtmlContainer htmlContent={text} />
            </div>
        </div>
    );
};
