import React from 'react';
import { Container } from 'vendors/semantic-ui';

import { HtmlContainer } from 'modules/shared-ui/components';

import { InnerAdvantageContainerProps } from '../inner-advantage-container-type';

import styles from './classic-advantage-container-component.module.css';

export const ClassicAdvantageContainer: React.FC<InnerAdvantageContainerProps> = ({
    title,
    content,
    titleStyle,
    textStyle,
    containerClassName,
    children,
}: InnerAdvantageContainerProps) => {
    return (
        <div className={`${styles.assetSection}${containerClassName ? ` ${containerClassName}` : ''}`}>
            <Container className={styles.assetContainer}>
                <div className={styles.container}>
                    <div className={styles.assetTitle} style={titleStyle}>
                        {title}
                    </div>
                    <div className={styles.assetText} style={textStyle}>
                        <HtmlContainer htmlContent={content} />
                    </div>
                    {children}
                </div>
            </Container>
        </div>
    );
};
