import React from 'react';
import { Link } from 'react-router-dom';
import { Placeholder } from 'vendors/semantic-ui';

import { buildComponentArray } from 'modules/shared/utils';
import { getDefault, readCMSValue } from 'modules/shared-cms/utils';
import { CMSImageContainer } from 'modules/shared-cms/components';

import { ImagesListBlocProps, ImagesListElementProps } from './images-list-bloc-type';

import styles from './images-list-bloc-component.module.css';

export const ImagesListBloc: React.FC<ImagesListBlocProps> = React.memo(
    ({
        list,
        style4Items = false,
        style6Items = false,
        fullWidth = false,
        squareItems = false,
        captionClassName,
    }: ImagesListBlocProps) => {
        const uwList = readCMSValue(list);

        return (
            <div className={styles.root}>
                <div
                    className={`${styles.content} ${fullWidth ? styles.fullWidth : ''} ${
                        squareItems ? styles.square : ''
                    }`}
                >
                    {uwList &&
                        buildComponentArray<ImagesListElementProps>(
                            uwList,
                            (itemProps: ImagesListElementProps, key: number): JSX.Element => {
                                const className = `${styles.item} ${style6Items ? styles.sixItem : ''} ${
                                    style4Items ? styles.fourItem : ''
                                } ${itemProps.highlight ? styles.highlight : ''} ${fullWidth ? styles.fullWidth : ''} ${
                                    squareItems ? styles.square : ''
                                }`;
                                return (
                                    <div key={`img-list-block-${key}`} className={className}>
                                        <Link to={getDefault(readCMSValue(itemProps.pictureLink), '#')}>
                                            <CMSImageContainer picture={itemProps.picture} />
                                            {itemProps.text && (
                                                <p className={`${styles.pictureCaption} ${captionClassName || ''}`}>
                                                    {itemProps.text}
                                                </p>
                                            )}
                                        </Link>
                                    </div>
                                );
                            },
                        )}
                </div>
            </div>
        );
    },
);

export const ImagesListBlocLoader: React.FC = () => {
    return (
        <div className={styles.background}>
            <Placeholder className={styles.title} style={{ maxWidth: '100%' }}>
                <Placeholder.Header>
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
        </div>
    );
};
