import React from 'react';

import { DoubleStaggeredBloc } from '../../base';
import { DoubleStaggeredBlocContainerProps } from './double-staggered-bloc-container-type';

export const DoubleStaggeredBlocContainer: React.FC<DoubleStaggeredBlocContainerProps> = ({
    doubleStaggeredText,
    doubleStaggeredColor,
    doubleStaggeredPicture1,
    doubleStaggeredPicture2,
}: DoubleStaggeredBlocContainerProps) => {
    return (
        <React.Fragment>
            {doubleStaggeredText && doubleStaggeredPicture1 && doubleStaggeredPicture2 && (
                <DoubleStaggeredBloc
                    text={doubleStaggeredText}
                    color={doubleStaggeredColor}
                    picture1={doubleStaggeredPicture1}
                    picture2={doubleStaggeredPicture2}
                />
            )}
        </React.Fragment>
    );
};
