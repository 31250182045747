import React from 'react';

import { LabelTitle } from '../../base';
import { LabelTitleContainerProps } from './label-title-container-type';

export const LabelTitleContainer: React.FC<LabelTitleContainerProps> = ({
    labelTitle,
    labelTitleColor,
    labelFontSizeTitle,
}: LabelTitleContainerProps) => {
    return <LabelTitle fontSize={labelFontSizeTitle} textColor={labelTitleColor} title={labelTitle} />;
};
