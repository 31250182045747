import React from 'react';

import { getDefault, readCMSValue } from 'modules/shared-cms/utils';

import { Description } from '../../base';
import { MentionContainerProps } from '.';

export const MentionContainer: React.FC<MentionContainerProps> = ({
    mention: wrappedMention,
    mentionFontSize: wrappedMentionFontSize,
    mentionTextColor: wrappedMentionTextColor,
}: MentionContainerProps) => {
    const {
        mention = getDefault(readCMSValue(wrappedMention), ''),
        mentionFontSize = readCMSValue(wrappedMentionFontSize),
        mentionTextColor = readCMSValue(wrappedMentionTextColor),
    } = {};

    return (
        <React.Fragment>
            {mention && <Description text={mention} fontSize={mentionFontSize} textColor={mentionTextColor} />}
        </React.Fragment>
    );
};
