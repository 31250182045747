import * as React from 'react';

import { getDefault, readCMSValue, buildCMSStyles } from 'modules/shared-cms/utils';
import { TextColorProps, GlobalFontSizeProps, JustifyProps } from 'modules/shared-cms/cms-base-type';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { HtmlContainer } from 'modules/shared-ui/components';

import { VideoBloc } from '../video-bloc';
import { SimpleStaggeredBlocProps } from './simple-staggered-bloc-type';

import styles from './simple-staggered-bloc-component.module.css';

export const SimpleStaggeredBloc: React.FC<SimpleStaggeredBlocProps> = ({
    title: wpTitle,
    titleFontSize: wpTitleFontSize,
    leftText: wpLeftText,
    leftTextFontSize: wpLeftTextFontSize,
    leftTextColor: wpLeftTextColor,
    leftTextAlignment: wpLeftTextAlignment,
    leftPicture: wpLeftPicture,
    leftVideoLink: wpLeftVideoLink,
    leftVideoPlaceholder: wpLeftVideoPlaceholder,
    rightText: wpRightText,
    rightTextFontSize: wpRightTextFontSize,
    rightTextColor: wpRightTextColor,
    rightTextAlignment: wpRightTextAlignment,
    rightPicture: wpRightPicture,
    rightVideoLink: wpRightVideoLink,
    rightVideoPlaceholder: wpRightVideoPlaceholder,
    hideEmptyBloc,
}: SimpleStaggeredBlocProps) => {
    const {
        title = readCMSValue(wpTitle),
        titleFontSize = getDefault(readCMSValue(wpTitleFontSize), 20),
        leftText = readCMSValue(wpLeftText),
        leftTextFontSize = getDefault(readCMSValue(wpLeftTextFontSize), 16),
        leftTextColor = getDefault(readCMSValue(wpLeftTextColor), 'black'),
        leftTextAlignment = getDefault(readCMSValue(wpLeftTextAlignment), 'centré'),
        leftPicture = getDefault(readCMSValue(wpLeftPicture), []),
        leftVideoLink = getDefault(readCMSValue(wpLeftVideoLink), ''),
        leftVideoPlaceholder = getDefault(readCMSValue(wpLeftVideoPlaceholder), []),
        rightText = readCMSValue(wpRightText),
        rightTextFontSize = getDefault(readCMSValue(wpRightTextFontSize), 16),
        rightTextColor = getDefault(readCMSValue(wpRightTextColor), 'black'),
        rightTextAlignment = getDefault(readCMSValue(wpRightTextAlignment), 'centré'),
        rightPicture = getDefault(readCMSValue(wpRightPicture), []),
        rightVideoLink = getDefault(readCMSValue(wpRightVideoLink), ''),
        rightVideoPlaceholder = getDefault(readCMSValue(wpRightVideoPlaceholder), []),
    } = {};

    if (leftText || leftPicture || rightText || rightPicture) {
        const leftEnabled = leftText || (leftPicture && leftPicture.length) || leftVideoLink;
        const rightEnabled = rightText || (rightPicture && rightPicture.length) || rightVideoLink;

        return (
            <div className={styles.container}>
                {title && (
                    <div
                        className={styles.title}
                        style={buildCMSStyles<GlobalFontSizeProps>({
                            fontSize: titleFontSize,
                        })}
                    >
                        {title}
                    </div>
                )}
                <div className={styles.wrapper}>
                    {(!hideEmptyBloc || leftEnabled) && (
                        <div
                            className={styles.leftWrapper}
                            style={!rightEnabled && hideEmptyBloc ? { width: '100%' } : undefined}
                        >
                            {leftText && (
                                <div
                                    className={styles.leftTextWrapper}
                                    style={buildCMSStyles<GlobalFontSizeProps & TextColorProps & JustifyProps>({
                                        fontSize: leftTextFontSize,
                                        textColor: leftTextColor,
                                        justification: leftTextAlignment,
                                    })}
                                >
                                    <HtmlContainer htmlContent={leftText} />
                                </div>
                            )}
                            {leftPicture && (
                                <div className={styles.picture}>
                                    <CMSImageContainer picture={leftPicture} />
                                </div>
                            )}
                            {leftVideoLink && (
                                <div className={styles.videoBloc}>
                                    <VideoBloc videoLink={leftVideoLink} videoPlaceholder={leftVideoPlaceholder} />
                                </div>
                            )}
                        </div>
                    )}
                    {(!hideEmptyBloc || rightEnabled) && (
                        <div
                            className={styles.rightWrapper}
                            style={!leftEnabled && hideEmptyBloc ? { width: '100%' } : undefined}
                        >
                            {rightText && (
                                <div
                                    className={styles.rightTextWrapper}
                                    style={buildCMSStyles<GlobalFontSizeProps & TextColorProps & JustifyProps>({
                                        fontSize: rightTextFontSize,
                                        textColor: rightTextColor,
                                        justification: rightTextAlignment,
                                    })}
                                >
                                    <HtmlContainer htmlContent={rightText} />
                                </div>
                            )}
                            {rightPicture && (
                                <div className={styles.picture}>
                                    <CMSImageContainer picture={rightPicture} />
                                </div>
                            )}
                            {rightVideoLink && (
                                <div className={styles.videoBloc}>
                                    <VideoBloc videoLink={rightVideoLink} videoPlaceholder={rightVideoPlaceholder} />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }

    return <React.Fragment />;
};
