import React from 'react';

import { getDefault, readCMSValue } from 'modules/shared-cms/utils';

import { Description } from '../../base';
import { DescriptionContainerProps } from '.';

export const DescriptionContainer: React.FC<DescriptionContainerProps> = ({
    description: wrappedDescription,
    descriptionFontSize: wrappedDescriptionFontSize,
    descriptionTextColor: wrappedDescriptionTextColor,
}: DescriptionContainerProps) => {
    const {
        description = getDefault(readCMSValue(wrappedDescription), ''),
        descriptionFontSize = readCMSValue(wrappedDescriptionFontSize),
        descriptionTextColor = readCMSValue(wrappedDescriptionTextColor),
    } = {};

    return <Description small text={description} fontSize={descriptionFontSize} textColor={descriptionTextColor} />;
};
