import { WithFetchDatasByIdFromCMS } from 'modules/shared-cms/hocs';

import { CarrouselVisuelsLoader } from './carrousel-visuels.loader';
import { CarrouselVisuelsProps } from './carrousel-visuels.types';
import { QUERY } from './carrousel-visuels.query';

export const enhancer = WithFetchDatasByIdFromCMS<CarrouselVisuelsProps>(
    QUERY,
    CarrouselVisuelsLoader,
    'findCarrouselVisuelsContent',
);
