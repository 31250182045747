import * as React from 'react';

import { GlobalFontSizeProps, TextColorProps } from 'modules/shared-cms/cms-base-type';
import { buildCMSStyles, readCMSValue, getDefault } from 'modules/shared-cms/utils';
import { HtmlContainer } from 'modules/shared-ui/components';

import { LabelTitleProps } from './label-title-component-type';

import styles from './label-title-component.module.css';

const defaultProps: LabelTitleProps = {
    title: '',
    fontSize: 18,
    textColor: '#313030',
};

export const LabelTitle: React.FC<LabelTitleProps> = React.memo((props: LabelTitleProps) => {
    const propsPrivate: LabelTitleProps = { ...defaultProps, ...props };
    const { fontSize, textColor, title } = propsPrivate;

    const style = buildCMSStyles<GlobalFontSizeProps & TextColorProps>({
        fontSize: readCMSValue(fontSize),
        textColor: readCMSValue(textColor),
    });

    const innerValue = getDefault(readCMSValue(title), '');

    return (
        <React.Fragment>
            <div className={styles.title}>
                <HtmlContainer node="h1" htmlContent={innerValue} style={style} />
            </div>
        </React.Fragment>
    );
});
