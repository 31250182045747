/* eslint-disable react/no-array-index-key */
import React from 'react';

import { useInfiniteScrollPaging } from 'modules/shared/hooks';

import { Thumb } from '../thumb';
import { ThumbListProps } from './thumb-list-type';

import styles from './thumb-list-component.module.css';

export const ThumbList: React.FC<ThumbListProps> = React.memo(
    ({ thumbs, style, legendStyle, useBrandId, fullWidth, placeholderClassName }: ThumbListProps) => {
        const [thumbList] = useInfiniteScrollPaging(thumbs, 12);

        return (
            <React.Fragment>
                <div className={`${styles.listContainer} ${fullWidth ? styles.fullWidth : ''}`}>
                    {thumbList.map((thumb, key) => {
                        if (!thumb) {
                            return <React.Fragment key={key} />;
                        }

                        return (
                            <Thumb
                                key={key}
                                {...thumb}
                                style={style}
                                legendStyle={legendStyle}
                                useBrandId={useBrandId}
                                placeholderClassName={placeholderClassName}
                            />
                        );
                    })}
                </div>
            </React.Fragment>
        );
    },
);
