/* eslint-disable react/no-array-index-key */
import React, { PropsWithChildren } from 'react';

import { ModelBloc } from 'modules/shared-cms-advanced/components';
import { SubTitleProps, TitleProps } from 'modules/shared-cms/cms-base-type';
import { buildCMSStyles, extractCMSOrDefault } from 'modules/shared-cms/utils';
import { Carousel } from 'modules/shared-ui-advanced';
import { CtaButton } from 'modules/shared-ui/components';

import { CarrouselVisuelsItem, CarrouselVisuelsProps } from './carrousel-visuels.types';
import { enhancer } from './carrousel-visuels.enhancer';

import styles from './carrousel-visuels.module.css';

const carrouselVisuels: React.FC<CarrouselVisuelsProps> = React.memo(
    (props: PropsWithChildren<CarrouselVisuelsProps>) => {
        const {
            superTitle = extractCMSOrDefault(props.superTitle, ''),
            superTitleFontSize = extractCMSOrDefault(props.superTitleFontSize, 30),
            title = extractCMSOrDefault(props.title, ''),
            titleFontSize = extractCMSOrDefault(props.titleFontSize, 30),
            subTitle = extractCMSOrDefault(props.subTitle, ''),
            subTitleFontSize = extractCMSOrDefault(props.subTitleFontSize, 16),
            backgroundColor = extractCMSOrDefault(props.backgroundColor, '#FFFFFF'),
            color = extractCMSOrDefault(props.color, '#000000'),
            ctaText = extractCMSOrDefault(props.ctaText, ''),
            ctaLink = extractCMSOrDefault(props.ctaLink, ''),
            ctaStyle = extractCMSOrDefault(props.ctaStyle, 'normal'),
            altMode = extractCMSOrDefault(props.altMode, false),
            items = extractCMSOrDefault<CarrouselVisuelsItem[]>(props.items, []),
        } = {};

        const superTitleStyle = buildCMSStyles<TitleProps>({
            title: superTitle,
            fontSizeTitle: superTitleFontSize,
        });
        const titleStyle = buildCMSStyles<TitleProps>({ title, fontSizeTitle: titleFontSize });
        const subTitleStyle = buildCMSStyles<SubTitleProps>({
            subTitle,
            fontSizeSubTitle: subTitleFontSize,
        });

        return (
            <React.Fragment>
                {superTitle !== '' && (
                    <div className={styles.superTitle} style={superTitleStyle}>
                        <span>{superTitle}</span>
                    </div>
                )}
                <div className={styles.wrapper} style={{ backgroundColor }}>
                    <div className={styles.content} style={{ color }}>
                        {(title !== '' || subTitle !== '') && (
                            <div className={styles.textContent}>
                                {title !== '' && (
                                    <div className={styles.title} style={titleStyle}>
                                        {title}
                                    </div>
                                )}
                                {subTitle !== '' && (
                                    <div className={styles.description} style={subTitleStyle}>
                                        {subTitle}
                                    </div>
                                )}
                            </div>
                        )}
                        {(items.length > 0 || props.children) && (
                            <div className={styles.carousel}>
                                <Carousel cards={5} dark auto pictoMode={altMode}>
                                    {items.length > 0
                                        ? items.slice().map((item, index) => {
                                              const itemProps = {
                                                  ...item,
                                                  textColor: item.color,
                                                  color: item.backgroundColor,
                                                  richCaption: true,
                                                  className: altMode ? styles.altMode : '',
                                              };
                                              return (
                                                  <React.Fragment key={`carrousel-visuels-${item.title}-${index}`}>
                                                      <ModelBloc {...itemProps} />
                                                  </React.Fragment>
                                              );
                                          })
                                        : props.children}
                                </Carousel>
                            </div>
                        )}
                    </div>
                    {ctaText && ctaLink && (
                        <div className={styles.ctaButtonContainer}>
                            <CtaButton ctaLink={ctaLink} ctaText={ctaText} ctaStyle={ctaStyle} />
                        </div>
                    )}
                </div>
            </React.Fragment>
        );
    },
);

export const CarrouselVisuels = enhancer(carrouselVisuels);
