import React from 'react';

import { PromotionBloc } from '../../components';

import { PromoProps } from './promo-type';

import enhancer from './promo-enhancer';

const promo: React.FC<PromoProps> = React.memo(
    ({
        promotionSuperTitle,
        promotionTitle,
        promotionSubTitle,
        promotionColor,
        promotionTextColor,
        promotionCtaLink,
        promotionCtaText,
        promotionCtaStyle,
        promotionItems,
    }: PromoProps) => {
        return (
            <React.Fragment>
                <PromotionBloc
                    superTitle={promotionSuperTitle}
                    title={promotionTitle}
                    subTitle={promotionSubTitle}
                    textColor={promotionTextColor}
                    color={promotionColor}
                    ctaLink={promotionCtaLink}
                    ctaText={promotionCtaText}
                    ctaStyle={promotionCtaStyle}
                    items={promotionItems}
                />
            </React.Fragment>
        );
    },
);

export const Promo = enhancer(promo);
