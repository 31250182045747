import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';

export const ShowroomLoader: React.FC = React.memo(() => {
    return (
        <React.Fragment>
            <Placeholder style={{ maxWidth: '100%', height: '50px' }}>
                <Placeholder.Image />
            </Placeholder>
        </React.Fragment>
    );
});
