import React from 'react';

import { TitleBloc } from '../../components';
import { IntroductionProps } from './introduction-type';

import enhancer from './introduction-enhancer';

const introduction: React.FC<IntroductionProps> = React.memo((props: IntroductionProps) => {
    return <TitleBloc {...props} />;
});

export const Introduction = enhancer(introduction);
