import * as React from 'react';
import { Container } from 'vendors/semantic-ui';

import { buildCMSStyles, extractCMSOrDefault } from 'modules/shared-cms/utils';
import {
    TitleProps,
    TextColorProps,
    GlobalFontSizeProps,
    CMSImageContainerField,
} from 'modules/shared-cms/cms-base-type';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { HtmlContainer } from 'modules/shared-ui/components';

import { DualStaggeredBlocProps } from './dual-staggered-bloc-type';

import styles from './dual-staggered-bloc-component.module.css';

interface BlockItem {
    title: string;
    titleFontSize: number;
    titleColor: string;
    text: string;
    fontSize?: number;
    color?: string;
    picture: CMSImageContainerField[];
}

export const DualStaggeredBloc: React.FC<DualStaggeredBlocProps> = ({
    upperTitle,
    upperTitleFontSize,
    upperTitleColor,
    upperText,
    upperPicture,
    lowerTitle,
    lowerTitleFontSize,
    lowerTitleColor,
    lowerText,
    lowerPicture,
    suppTitle,
    suppTitleFontSize,
    suppTitleColor,
    suppText,
    suppPicture,
}) => {
    const items: BlockItem[] = [];

    const pUpperTitle = extractCMSOrDefault(upperTitle, '');
    const pUpperText = extractCMSOrDefault(upperText, '');
    const pUpperPicture = extractCMSOrDefault(upperPicture, []);
    if (pUpperTitle !== '' || pUpperText !== '' || pUpperPicture.length > 0) {
        items.push({
            title: pUpperTitle,
            titleFontSize: extractCMSOrDefault(upperTitleFontSize, 24),
            titleColor: extractCMSOrDefault(upperTitleColor, '#000000'),
            text: pUpperText,
            fontSize: 16,
            color: '#000000',
            picture: pUpperPicture,
        });
    }

    const pLowerTitle = extractCMSOrDefault(lowerTitle, '');
    const pLowerText = extractCMSOrDefault(lowerText, '');
    const pLowerPicture = extractCMSOrDefault(lowerPicture, []);
    if (pLowerTitle !== '' || pLowerText !== '' || pLowerPicture.length > 0) {
        items.push({
            title: pLowerTitle,
            titleFontSize: extractCMSOrDefault(lowerTitleFontSize, 24),
            titleColor: extractCMSOrDefault(lowerTitleColor, '#000000'),
            text: pLowerText,
            fontSize: 16,
            color: '#000000',
            picture: pLowerPicture,
        });
    }

    const pSuppTitle = extractCMSOrDefault(suppTitle, '');
    const pSuppText = extractCMSOrDefault(suppText, '');
    const pSuppPicture = extractCMSOrDefault(suppPicture, []);
    if (pSuppTitle !== '' || pSuppText !== '' || pSuppPicture.length > 0) {
        items.push({
            title: pSuppTitle,
            titleFontSize: extractCMSOrDefault(suppTitleFontSize, 24),
            titleColor: extractCMSOrDefault(suppTitleColor, '#000000'),
            text: pSuppText,
            fontSize: 16,
            color: '#000000',
            picture: pSuppPicture,
        });
    }

    return (
        <>
            {items.length > 0 && (
                <Container className={styles.root}>
                    <div>
                        {items.map((item, index) => {
                            const { title, titleFontSize, titleColor, text, fontSize, color, picture } = item;
                            return (
                                // eslint-disable-next-line react/no-array-index-key
                                <div key={`alternating-block-${title}-${index}`} className={styles.item}>
                                    {picture.length > 0 && (
                                        <div className={styles.textBlock}>
                                            {text.length > 0 && (
                                                <>
                                                    <div className={styles.textBlockH3}>
                                                        <h3
                                                            style={buildCMSStyles<TitleProps & TextColorProps>({
                                                                title,
                                                                fontSizeTitle: titleFontSize,
                                                                textColor: titleColor,
                                                            })}
                                                        >
                                                            {title}
                                                        </h3>
                                                    </div>
                                                    <HtmlContainer
                                                        className={styles.text}
                                                        htmlContent={text}
                                                        style={buildCMSStyles<GlobalFontSizeProps & TextColorProps>({
                                                            fontSize,
                                                            textColor: color,
                                                        })}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    )}
                                    <div className={styles.picture}>
                                        {picture && <CMSImageContainer picture={picture} />}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Container>
            )}
        </>
    );
};
