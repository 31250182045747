import { compose } from 'redux';
import { WithFetchDatasByIdFromCMS, WithFetchDatasByIdFromCMSInputProps, WithCMSSEO } from 'modules/shared-cms/hocs';

import { QUERY } from './sous-rubrique-landing-query';
import { SousRubriqueLandingLoader } from './sous-rubrique-landing-component-loader';
import { SousRubriqueLandingProps } from '.';

const enhancer = compose<React.ComponentType<WithFetchDatasByIdFromCMSInputProps>>(
    WithFetchDatasByIdFromCMS<SousRubriqueLandingProps>(
        QUERY,
        SousRubriqueLandingLoader,
        'findSousRubriqueLandingContent',
    ),
    WithCMSSEO(),
);
export default enhancer;
