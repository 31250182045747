import React from 'react';

import { HoveredImagesListBlocLoader, TitleBlocLoader, NewsLoader } from '../../components';

import styles from './sous-rubrique-landing-component.module.css';

export const SousRubriqueLandingLoader: React.FC = React.memo(() => {
    return (
        <React.Fragment>
            <div className={`${styles.root}`}>
                <TitleBlocLoader />
                <div className={`${styles.newsContainer}`}>
                    <div className={`${styles.newsWrapper}`}>
                        <NewsLoader />
                    </div>
                    <div className={`${styles.newsWrapper}`}>
                        <NewsLoader />
                    </div>
                    <div className={`${styles.newsWrapper}`}>
                        <NewsLoader />
                    </div>
                </div>
                <HoveredImagesListBlocLoader />
            </div>
        </React.Fragment>
    );
});
