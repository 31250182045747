/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Placeholder, Modal, Responsive } from 'vendors/semantic-ui';

import { useHover } from 'modules/shared/hooks';
import { buildComponentArray } from 'modules/shared/utils';
import {
    readCMSValue,
    buildCMSStyles,
    getDefault,
    getDefaultUrlPicture,
    extractCMSOrDefault,
} from 'modules/shared-cms/utils';
import { BackgroundColorProps, TextColorProps, TitleProps } from 'modules/shared-cms/cms-base-type';
import { ClickableContainer, CtaImage } from 'modules/shared-ui/components';
import { Carousel } from 'modules/shared-ui-advanced/components';
import { configUI } from 'config/config-ui';

import { ZoomedRibbonBlocProps, ZoomedRibbonElementProps } from './zoomed-ribbon-bloc-type';

import styles from './zoomed-ribbon-bloc-component.module.css';

type ZoomedRibbonContainerProps = React.PropsWithChildren<{
    elemNumber: number;
    keyPrefix: string;
}>;

const ZoomedRibbonElement: React.FC<ZoomedRibbonElementProps> = React.memo(
    ({ title, picture, list, index, ...rest }: ZoomedRibbonElementProps) => {
        const pictureValue = getDefaultUrlPicture(picture);
        const { hovered, bind } = useHover();
        return (
            <Modal
                closeIcon
                trigger={
                    <div {...rest}>
                        <ClickableContainer onHover={bind}>
                            <div {...bind} className={styles.zoomedRibbonElement}>
                                {pictureValue && (
                                    <img src={pictureValue} alt={readCMSValue(title)} className={styles.image} />
                                )}
                                <div className={styles.mask}>
                                    <div>{hovered && readCMSValue(title)}</div>
                                </div>
                            </div>
                            <div className={styles.legend}>{readCMSValue(title)}</div>
                        </ClickableContainer>
                    </div>
                }
            >
                <Modal.Content image className={styles.modalImg}>
                    {list && list.length > 1 ? (
                        <Carousel cards={1} auto={false} initialSlide={index} dark>
                            {list.map((item, key) => {
                                return (
                                    <React.Fragment key={`bloc-selection-modal-${key}`}>
                                        {pictureValue && (
                                            <div className={styles.carouselItemContainer}>
                                                <img
                                                    src={getDefaultUrlPicture(item.picture)}
                                                    alt={readCMSValue(item.title)}
                                                    className={styles.carouselItemImage}
                                                />
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </Carousel>
                    ) : (
                        <>{list && list.length > 0 && <CtaImage picture={list[0].picture}></CtaImage>}</>
                    )}
                </Modal.Content>
            </Modal>
        );
    },
);

const ZoomedRibbonContainer: React.FC<ZoomedRibbonContainerProps> = React.memo(
    ({ elemNumber, keyPrefix, children }: ZoomedRibbonContainerProps) => (
        <div className={styles.itemContainer}>
            {React.Children.toArray(children).map((child, index) => {
                return (
                    <div
                        key={`${keyPrefix}-${index}`}
                        style={{
                            width: `calc(100% / ${elemNumber})`,
                            marginTop: `${index > elemNumber - 1 ? '2rem' : ''}`,
                        }}
                    >
                        {child}
                    </div>
                );
            })}
        </div>
    ),
);

export const ZoomedRibbonBlocLoader: React.FC<ZoomedRibbonContainerProps> = ({
    elemNumber,
}: ZoomedRibbonContainerProps) => {
    return (
        <div className={styles.background}>
            <Placeholder className={styles.title} style={{ maxWidth: '100%' }}>
                <Placeholder.Header>
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <ZoomedRibbonContainer elemNumber={4} keyPrefix="zoomed-ribbon-placeholder">
                {[...Array(elemNumber)].map(() => (
                    <Placeholder>
                        <Placeholder.Image style={{ height: '200px' }} />
                    </Placeholder>
                ))}
            </ZoomedRibbonContainer>
        </div>
    );
};

export const ZoomedRibbonBloc: React.FC<ZoomedRibbonBlocProps> = React.memo(
    ({ title: pTitle, color, list, textColor, nbElement }: ZoomedRibbonBlocProps) => {
        const title = extractCMSOrDefault(pTitle, '');

        const [carouselIndex, setCarouselIndex] = React.useState(0);

        return (
            <div className={styles.background} style={buildCMSStyles<BackgroundColorProps>({ color })}>
                <div
                    className={styles.title}
                    style={buildCMSStyles<TitleProps & TextColorProps>({ title, fontSizeTitle: 21, textColor })}
                >
                    {title}
                </div>
                {list && list.length > 0 && (
                    <div className={styles.container}>
                        <Responsive minWidth={configUI.responsive.tablet.minWidth}>
                            <ZoomedRibbonContainer
                                elemNumber={getDefault(readCMSValue(nbElement), 4)}
                                keyPrefix={`bloc-selection-${title}`}
                            >
                                {buildComponentArray<ZoomedRibbonElementProps>(
                                    list,
                                    (childItem: ZoomedRibbonElementProps, index: number): JSX.Element => {
                                        return (
                                            <ZoomedRibbonElement
                                                key={`bloc-selection-item-${title}-${index}`}
                                                {...childItem}
                                                index={index}
                                                list={list}
                                            />
                                        );
                                    },
                                )}
                            </ZoomedRibbonContainer>
                        </Responsive>
                        <Responsive maxWidth={configUI.responsive.mobile.maxWidth}>
                            <div className={styles.sCarousel}>
                                <Carousel cards={1} auto={false} dark hideDots onSlideChange={setCarouselIndex}>
                                    {list.length > 0 &&
                                        list.map((item, index) => {
                                            const iTitle = extractCMSOrDefault(item.title, '');
                                            const iPictureValue = getDefaultUrlPicture(item.picture) || '';

                                            return (
                                                <div
                                                    key={`bloc-selection-item-${title}-${index}`}
                                                    className={styles.sCarouselItem}
                                                >
                                                    {iPictureValue.length > 0 && (
                                                        <img
                                                            className={styles.image}
                                                            src={iPictureValue}
                                                            alt={iTitle}
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}
                                </Carousel>
                                <div className={styles.sCarouselLegend}>
                                    <span>
                                        <b>{list[carouselIndex].title}</b>
                                    </span>
                                </div>
                            </div>
                        </Responsive>
                    </div>
                )}
            </div>
        );
    },
);
