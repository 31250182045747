import gql from 'graphql-tag';

export const QUERY = gql([
    `
query findSousRubriqueLandingContent($id: String!) 
{
  findSousRubriqueLandingContent(id: $id) 
  {
    data 
    {
      pageTitle {
        iv
      }      
      seoDescription {
        iv
      }
      seoKeywords {
        iv
      }
      seoThumbnail {
        iv {
          url
        }
      }
      intro {
        iv {id}
      }
      news {
        iv {id}
      }
      listeImageTitle 
      {
        iv
      }
      listeImageColor 
      {
        iv
      }
      listeImage 
      {
        iv 
        {
          title
          link
          picture 
          {
            url
            fileSize
          }
        }
      }

      selectionTitle {
        iv
      }
      selectionColor {
        iv
      }
      selectionTextColor {
        iv
      }
      selectionItems {
        iv {
          title
          subTitle
          color
          link
          picture {
              url
              fileSize
          }
          textColor
          justification
        }
      }
    }
  }
}
`,
]);
