import React from 'react';

import { buildCMSStyles, getDefault, readCMSValue } from 'modules/shared-cms/utils';
import { BackgroundColorProps, TextColorProps, TitleProps } from 'modules/shared-cms/cms-base-type';
import { Carousel } from 'modules/shared-ui-advanced/components';

import { ModelBloc } from '../model-bloc';
import { SelectionBlocProps } from './selection-bloc-type';

import styles from './selection-bloc-component.module.css';

const Background: React.FC<BackgroundColorProps> = props => {
    const { children } = props;
    return (
        <div className={styles.background} style={buildCMSStyles<BackgroundColorProps>(props)}>
            {children}
        </div>
    );
};
const Content: React.FC<TextColorProps> = props => {
    const { children } = props;
    return (
        <div className={styles.content} style={buildCMSStyles<TextColorProps>(props)}>
            {children}
        </div>
    );
};

export const SelectionBloc: React.FC<SelectionBlocProps> = React.memo(
    ({
        title: wrappedTitle,
        textColor: wrappedTextColor,
        color: wrappedColor,
        items: wrappedItems,
    }: SelectionBlocProps) => {
        const {
            title = getDefault(readCMSValue(wrappedTitle), ''),
            textColor = readCMSValue(wrappedTextColor),
            color = readCMSValue(wrappedColor),
            items = getDefault(readCMSValue(wrappedItems), []),
        } = {};
        return (
            <div className={styles.wrapper}>
                <Content textColor={textColor}>
                    <div className={styles.textContent}>
                        <div className={styles.title} style={buildCMSStyles<TitleProps>({ title, fontSizeTitle: 21 })}>
                            {title}
                        </div>
                    </div>
                    <Background color={color}>
                        <div className={styles.carousel}>
                            <Carousel cards={4} auto>
                                {items.slice().map(item => {
                                    return (
                                        <React.Fragment key={Math.random()}>
                                            <ModelBloc {...item} />
                                        </React.Fragment>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </Background>
                </Content>
            </div>
        );
    },
);
