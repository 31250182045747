import React, { PropsWithChildren } from 'react';
import { Placeholder } from 'vendors/semantic-ui';

import { readCMSValue, getDefault, buildCMSStyles, getDefaultUrlPicture } from 'modules/shared-cms/utils';
import {
    TextColorProps,
    JustifyProps,
    BackgroundColorProps,
    TitleProps,
    SubTitleProps,
} from 'modules/shared-cms/cms-base-type';

import { HtmlContainer } from 'modules/shared-ui/components';
import { ModelBlocProps, ModelBlocOwnProps } from './model-bloc-type';

import styles from './model-bloc-component.module.css';

const Wrapper: React.FC<PropsWithChildren<ModelBlocOwnProps>> = (props: PropsWithChildren<ModelBlocOwnProps>) => {
    const { link, className, children } = props;
    if (!link) {
        return <div className={`${styles.wrapper} ${className}`}>{children}</div>;
    }
    return (
        <a href={readCMSValue(link)} target="_blank" rel="noopener noreferrer" className={className}>
            <div className={styles.wrapper}>{children}</div>
        </a>
    );
};
const LegendWrapper: React.FC<TextColorProps & JustifyProps & BackgroundColorProps> = props => {
    const { children } = props;
    return (
        <div className={styles.legend} style={buildCMSStyles<BackgroundColorProps & JustifyProps>(props)}>
            {children}
        </div>
    );
};

export const ModelBloc: React.FC<ModelBlocProps> = React.memo(
    ({
        title: wrappedTitle,
        subTitle: wrappedSubTitle,
        textColor: wrappedTextColor,
        picture: wrappedPicture,
        link: wrappedLink,
        justification: wrappedJustification,
        color: wrappedColor,
        className,
        richCaption = false,
    }: ModelBlocProps) => {
        const {
            title = getDefault(readCMSValue(wrappedTitle), ''),
            subTitle = readCMSValue(wrappedSubTitle),
            textColor = readCMSValue(wrappedTextColor),
            picture = getDefaultUrlPicture(wrappedPicture),
            link = readCMSValue(wrappedLink),
            justification = readCMSValue(wrappedJustification),
            color = readCMSValue(wrappedColor),
        } = {};

        const titleStyle = buildCMSStyles<TitleProps & TextColorProps>({
            title,
            fontSizeTitle: 14,
            textColor,
        });
        const subtitleStyle = buildCMSStyles<SubTitleProps & TextColorProps>({
            subTitle,
            fontSizeSubTitle: 14,
            textColor,
        });

        return (
            <Wrapper link={link} className={className}>
                {picture && <img className={styles.imgContent} alt="" src={picture} />}
                {(title || subTitle) && (
                    <LegendWrapper color={color} justification={justification}>
                        {!richCaption ? (
                            <>
                                <div className={styles.title} style={titleStyle}>
                                    {title}
                                </div>
                                <div className={styles.subTitle} style={subtitleStyle}>
                                    {subTitle}
                                </div>
                            </>
                        ) : (
                            <>
                                <HtmlContainer
                                    className={styles.title}
                                    style={titleStyle}
                                    htmlContent={title}
                                ></HtmlContainer>
                                <HtmlContainer
                                    className={styles.subTitle}
                                    style={subtitleStyle}
                                    htmlContent={subTitle || ''}
                                ></HtmlContainer>
                            </>
                        )}
                    </LegendWrapper>
                )}
            </Wrapper>
        );
    },
);

export const ModelBlocLoader: React.FC = () => {
    return (
        <Wrapper>
            <Placeholder fluid>
                <Placeholder.Image rectangular />
                <Placeholder.Line length="full" />
            </Placeholder>
        </Wrapper>
    );
};
