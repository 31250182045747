import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindCarrouselContent($id: String!)
{
  findCarrouselContent(id: $id) {
    data {

      carouselItems {
        iv {
          title
          subTitle
          fontSizeTitle
          fontSizeSubTitle
          tagline
          width
          color
          ctaLink
          ctaText
          ctaStyle
          ctaLink2
          ctaText2
          ctaStyle2
          picture {
            url
            fileSize
          }
          textColor
          horizontalPosition
          verticalPosition
          justification
        }
      }

    }
  }
}`,
]);
