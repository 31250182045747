import { compose } from 'redux';
import { WithFetchDatasByIdFromCMS, WithFetchDatasByIdFromCMSInputProps, WithCMSSEO } from 'modules/shared-cms/hocs';

import { QUERY } from './rubrique-landing-query';
import { RubriqueLandingLoader } from './rubrique-landing-component-loader';
import { RubriqueLandingProps } from '.';

const enhancer = compose<React.ComponentType<WithFetchDatasByIdFromCMSInputProps>>(
    WithFetchDatasByIdFromCMS<RubriqueLandingProps>(QUERY, RubriqueLandingLoader, 'findRubriqueLandingContent'),
    WithCMSSEO(),
);
export default enhancer;
