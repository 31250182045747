import React from 'react';

import { buildComponentArray } from 'modules/shared/utils';
import { getDefault, readCMSValue } from 'modules/shared-cms/utils';

import { HtmlContainer } from 'modules/shared-ui/components';
import { News, NewsProps } from '../../components';
import { ActualiteProps } from './actualite-type';

import enhancer from './actualite-enhancer';

import styles from './actualite-component.module.css';

const actualite: React.FC<ActualiteProps> = React.memo(
    ({ intro, introFontSize, introTextColor, news }: ActualiteProps) => {
        const introText = getDefault(readCMSValue(intro), '');
        const fontSize = getDefault(readCMSValue(introFontSize), 14);
        const color = getDefault(readCMSValue(introTextColor), '#000000');
        const uwNews = readCMSValue(news);
        return (
            <div className={styles.root}>
                {introText !== '' && (
                    <div className={styles.introText}>
                        <HtmlContainer htmlContent={introText} style={{ fontSize, color }} />
                    </div>
                )}
                <div className={`${styles.newsContainer}`}>
                    {uwNews &&
                        buildComponentArray<NewsProps>(
                            uwNews,
                            (itemProps: NewsProps, key: number): JSX.Element => {
                                return (
                                    <div className={`${styles.newsWrapper}`} key={`actualite-news-item-${key}`}>
                                        <News {...itemProps} />
                                    </div>
                                );
                            },
                        )}
                </div>
            </div>
        );
    },
);

export const Actualite = enhancer(actualite);
