import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';

import { buildComponentArray } from 'modules/shared/utils';
import { readCMSValue, buildCMSStyles } from 'modules/shared-cms/utils';
import { BackgroundColorProps } from 'modules/shared-cms/cms-base-type';

import { HoveredImageBloc, HoveredImageBlocProps } from '../hovered-image-bloc';
import { HoveredImagesListBlocProps } from './hovered-images-list-bloc-type';

import styles from './hovered-images-list-bloc-component.module.css';

export const HoveredImagesListBloc: React.FC<HoveredImagesListBlocProps> = React.memo(
    ({ title, list, color, style }: HoveredImagesListBlocProps) => {
        const uwList = readCMSValue(list);
        return (
            <div className={styles.background} style={buildCMSStyles<BackgroundColorProps>({ color })}>
                <div className={styles.title}>{readCMSValue(title)}</div>

                <div className={styles.container}>
                    <HoveredImagesListContainer>
                        {uwList &&
                            buildComponentArray<HoveredImageBlocProps>(
                                uwList,
                                (childItem: HoveredImageBlocProps, key: number): JSX.Element => {
                                    return <HoveredImageBloc style={style} {...childItem} key={key} />;
                                },
                            )}
                    </HoveredImagesListContainer>
                </div>
            </div>
        );
    },
);

export const HoveredImagesListBlocLoader: React.FC = () => {
    return (
        <div className={styles.background}>
            <Placeholder className={styles.title} style={{ maxWidth: '100%' }}>
                <Placeholder.Header>
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <HoveredImagesListContainer>
                <Placeholder>
                    <Placeholder.Image style={{ height: '200px' }} />
                </Placeholder>
                <Placeholder>
                    <Placeholder.Image style={{ height: '200px' }} />
                </Placeholder>
                <Placeholder>
                    <Placeholder.Image style={{ height: '200px' }} />
                </Placeholder>
                <Placeholder>
                    <Placeholder.Image style={{ height: '200px' }} />
                </Placeholder>
            </HoveredImagesListContainer>
        </div>
    );
};

export const HoveredImagesListContainer: React.FC = React.memo(props => {
    const { children } = props;
    return (
        <div className={styles.itemContainer}>
            {React.Children.toArray(children).map(child => {
                return <div className={styles.item}>{child}</div>;
            })}
        </div>
    );
});
