import React from 'react';

import { RibbonBloc } from '../../base';
import { RibbonBlocContainerProps } from './ribbon-bloc-container-type';

export const RibbonBlocContainer: React.FC<RibbonBlocContainerProps> = ({
    ribbonTitle,
    ribbonPicture,
    ribbonColor,
    ribbonList,
}: RibbonBlocContainerProps) => {
    return <RibbonBloc title={ribbonTitle} picture={ribbonPicture} color={ribbonColor} list={ribbonList} />;
};
