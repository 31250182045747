/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Slider from 'react-slick';
import { Placeholder, Modal } from 'vendors/semantic-ui';

import { extractCMSOrDefault } from 'modules/shared-cms/utils';
import { HtmlContainer } from 'modules/shared-ui/components';
import { Carousel } from 'modules/shared-ui-advanced/components';

import { ZoomedPortfolioBlocProps, ZoomedPortfolioElementProps } from './zoomed-portfolio-type';

import styles from './zoomed-portfolio-component.module.css';

interface ZoomedPortfolioContainerProps {
    elemNumber: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: any;
}

const ZoomedPortfolioElement: React.FC<ZoomedPortfolioElementProps> = ({
    title: pTitle,
    titleColor: pTitleColor,
    legend: pLegend,
    legendColor: pLegendColor,
    description: pDescription,
    descriptionColor: pDescriptionColor,
    images,
    itemStyle,
}: ZoomedPortfolioElementProps) => {
    const {
        title = extractCMSOrDefault(pTitle, ''),
        titleColor = extractCMSOrDefault(pTitleColor, '#000000'),
        legend = extractCMSOrDefault(pLegend, ''),
        legendColor = extractCMSOrDefault(pLegendColor, '#000000'),
        description = extractCMSOrDefault(pDescription, ''),
        descriptionColor = extractCMSOrDefault(pDescriptionColor, '#000000'),
        pictures = extractCMSOrDefault(images, []).map(p => {
            const parsedUrl = p.url.split('?')[0];
            return {
                url: parsedUrl,
                fileName: parsedUrl,
                largeFileName: parsedUrl,
                thumbnailFileName: parsedUrl,
            };
        }),
    } = {};

    const oneItem = pictures.length === 1;
    const ref = React.useRef<Slider>(null);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [hoveredItem, setHoveredItem] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    React.useEffect(() => {
        if (ref != null && ref.current != null) {
            if (hoveredItem) {
                ref.current.slickNext();
                ref.current.slickPlay();
            } else {
                ref.current.slickPause();
                ref.current.slickGoTo(0);
            }
        }
    }, [hoveredItem]);

    return (
        <div className={styles.zElemRoot}>
            <Modal size="fullscreen" closeIcon open={modalOpen} onClose={() => setModalOpen(false)}>
                <Modal.Content image className={styles.zElemModalContent}>
                    <React.Fragment>
                        {pictures.length === 1 && (
                            <div className={styles.zElemModalContentItemContainer}>
                                <img src={pictures[0].url} alt="" className={styles.zElemModalContentImg} />
                            </div>
                        )}
                        {pictures.length > 1 && (
                            <Carousel cards={1} auto={false} initialSlide={currentIndex} dark>
                                {pictures.map((item, index) => {
                                    return (
                                        <div
                                            key={`modal-content-${title}-${index}`}
                                            className={styles.zElemModalContentItemContainer}
                                        >
                                            <img className={styles.zElemModalContentImg} src={item.url} alt="" />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        )}
                        {legend !== '' && (
                            <div className={styles.zElemModalContentLegend}>
                                <HtmlContainer htmlContent={legend} />
                            </div>
                        )}
                    </React.Fragment>
                </Modal.Content>
            </Modal>
            <div
                className={styles.zElemModalTrigger}
                style={itemStyle}
                onMouseEnter={!oneItem ? () => setHoveredItem(true) : undefined}
                onMouseLeave={!oneItem ? () => setHoveredItem(false) : undefined}
            >
                {pictures.length > 0 && (
                    <Slider
                        ref={ref}
                        infinite
                        slidesToShow={1}
                        arrows={false}
                        dots={!oneItem}
                        autoplay
                        autoplaySpeed={1000}
                        swipe={false}
                        pauseOnDotsHover
                        dotsClass={`slick-dots ${styles.sliderDots}`}
                        customPaging={() => <button type="button" className={styles.sliderDot} aria-hidden />}
                        afterChange={setCurrentIndex}
                        responsive={[
                            {
                                breakpoint: 768,
                                settings: {
                                    swipe: true,
                                },
                            },
                        ]}
                    >
                        {pictures.map((p, i) => {
                            return <img key={`${title}-${i}`} src={p.url} alt="" onClick={() => setModalOpen(true)} />;
                        })}
                    </Slider>
                )}
                <div className={styles.zElemTriggerText} onClick={() => setModalOpen(true)}>
                    <HtmlContainer className={styles.zElemTitle} htmlContent={title} style={{ color: titleColor }} />
                    <HtmlContainer className={styles.zElemLegend} htmlContent={legend} style={{ color: legendColor }} />
                    <HtmlContainer
                        className={styles.zElemDescription}
                        htmlContent={description}
                        style={{ color: descriptionColor }}
                    />
                </div>
            </div>
        </div>
    );
};

export const ZoomedPortfolioBlocLoader: React.FC<ZoomedPortfolioContainerProps> = () => {
    return (
        <div className={styles.background}>
            <Placeholder className={styles.title} style={{ maxWidth: '100%' }}>
                <Placeholder.Header>
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
        </div>
    );
};

export const ZoomedPortfolioBloc: React.FC<ZoomedPortfolioBlocProps> = React.memo(
    ({ title: pTitle, textColor: pTitleColor, color, list: pItems, collection }: ZoomedPortfolioBlocProps) => {
        const {
            backgroundColor = extractCMSOrDefault(color, '#FFFFFF'),
            title = extractCMSOrDefault(pTitle, ''),
            titleColor = extractCMSOrDefault(pTitleColor, '#000000'),
            items = extractCMSOrDefault<ZoomedPortfolioElementProps[]>(pItems, []),
        } = {};
        return (
            <div
                className={styles.zBlockRoot}
                style={{
                    backgroundColor,
                    ...(backgroundColor.toLowerCase() !== '#ffffff' ? { padding: '3rem 0' } : {}),
                }}
            >
                <div className={styles.zBlockTitle} style={{ color: titleColor }}>
                    {title}
                </div>
                {items.length > 0 && (
                    <div className={styles.zElems}>
                        {items.map((item, index) => {
                            return (
                                <div key={`bloc-collection-${title}-${index}`} className={styles.zElemContainer}>
                                    <ZoomedPortfolioElement {...item} collection={collection} />
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    },
);
