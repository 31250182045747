/* eslint-disable react/no-array-index-key */
import React from 'react';
import { TextColorProps, TitleProps } from 'modules/shared-cms/cms-base-type';
import { buildCMSStyles, getDefault, readCMSValue } from 'modules/shared-cms/utils';
import { Link } from 'react-router-dom';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { DescriptionContainer } from '../../container/description-container/description-container-component';

import { RubricBlocProps } from './rubric-bloc.types';

import styles from './rubric-bloc.module.css';

export const RubricBloc: React.FC<RubricBlocProps> = ({
    rubricTitle,
    rubricTitleFontSize,
    rubricSubtitle,
    rubricSubtitleFontSize,
    rubricColor,
    rubricItems,
}: RubricBlocProps) => {
    const {
        title = getDefault(readCMSValue(rubricTitle), ''),
        titleFontSize = getDefault(readCMSValue(rubricTitleFontSize), 24),
        subtitle = getDefault(readCMSValue(rubricSubtitle), ''),
        subtitleFontSize = getDefault(readCMSValue(rubricSubtitleFontSize), 12),
        textColor = getDefault(readCMSValue(rubricColor), '#000000'),
        items = getDefault(readCMSValue(rubricItems), []),
    } = {};

    const titleStyle = buildCMSStyles<TitleProps & TextColorProps>({
        title,
        fontSizeTitle: titleFontSize,
        textColor,
    });

    return (
        <div className={styles.root}>
            {title !== '' && (
                <span className={styles.title} style={titleStyle}>
                    {title}
                </span>
            )}
            {subtitle !== '' && (
                <DescriptionContainer
                    description={subtitle}
                    descriptionFontSize={subtitleFontSize}
                    descriptionTextColor={textColor}
                />
            )}
            {items.length > 0 && (
                <div className={styles.itemContainer}>
                    {items.map((item, index) => {
                        const {
                            link = getDefault(readCMSValue(item.pictureLink), '#'),
                            text = getDefault(readCMSValue(item.text), ''),
                            className = `${styles.item} ${item.highlight ? styles.fullWidth : styles.halfWidth}`,
                        } = {};
                        return (
                            <React.Fragment key={`${text}-${index}`}>
                                <div className={className}>
                                    <Link to={link}>
                                        <CMSImageContainer picture={item.picture} />
                                        {text !== '' && <p className={styles.itemText}>{text}</p>}
                                    </Link>
                                </div>
                            </React.Fragment>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
