import React from 'react';

import { Expander } from 'modules/shared-ui-advanced/components';

import { InnerAdvantageContainerProps } from '../inner-advantage-container-type';

import styles from './expandable-advantage-container-component.module.css';

export const ExpandableAdvantageContainer: React.FC<InnerAdvantageContainerProps> = ({
    title,
    content,
    titleStyle,
    textStyle,
    containerClassName,
    children,
    secondaryTitle,
}: InnerAdvantageContainerProps) => {
    return (
        <div className={`${styles.container} ${containerClassName ? ` ${containerClassName}` : ''}`}>
            <Expander
                headerTitle={title}
                titleStyle={titleStyle}
                contentStyle={textStyle}
                secondaryChildren={children}
                secondaryHeaderTitle={secondaryTitle}
            >
                {content}
            </Expander>
        </div>
    );
};
