import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';
import { Link } from 'react-router-dom';

import { buildComponentArray } from 'modules/shared/utils';
import { readCMSValue, buildCMSStyles, getDefault } from 'modules/shared-cms/utils';
import { BackgroundColorProps, TitleProps, SubTitleProps, TextColorProps } from 'modules/shared-cms/cms-base-type';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { CtaButton, HtmlContainer } from 'modules/shared-ui/components';

import { TrendBlocProps, TrendElementProps } from './trend-bloc-type';

import styles from './trend-bloc-component.module.css';

const Wrapper: React.FC<BackgroundColorProps> = props => {
    const { children } = props;
    return (
        <div className={styles.wrapper} style={buildCMSStyles<BackgroundColorProps>(props)}>
            {children}
        </div>
    );
};

export const TrendBloc: React.FC<React.PropsWithChildren<TrendBlocProps>> = React.memo(
    ({
        color: wrappedColor,
        textColor: wrappedTextColor,
        title: wrappedTitle,
        fontSizeTitle: wrappedFontSizeTitle,
        subTitle: wrappedSubTitle,
        fontSizeSubTitle,
        mainPicture: wrappedPicture,
        mainLink: wrappedMainLink,
        list,
        ctaText: wrappedCtaText,
        ctaLink: wrappedCtaLink,
        ctaStyle: wrappedCtaStyle,
        inverted: wrappedInverted,
        elementsHaveHtmlTitle,
        children,
    }: React.PropsWithChildren<TrendBlocProps>) => {
        const {
            color = readCMSValue(wrappedColor),
            textColor = getDefault(readCMSValue(wrappedTextColor), '#000000'),
            fontSizeTitle = getDefault(readCMSValue(wrappedFontSizeTitle), 12),
            title = getDefault(readCMSValue(wrappedTitle), ''),
            subTitle = getDefault(readCMSValue(wrappedSubTitle), ''),
            mainPicture = getDefault(readCMSValue(wrappedPicture), []),
            mainLink = getDefault(readCMSValue(wrappedMainLink), ''),
            ctaText = readCMSValue(wrappedCtaText),
            ctaLink = readCMSValue(wrappedCtaLink),
            ctaStyle = readCMSValue(wrappedCtaStyle),
            inverted = readCMSValue(wrappedInverted),
        } = {};

        return (
            <React.Fragment>
                <Wrapper color={color}>
                    <div className={styles.content}>
                        <div className={styles.textContent} style={buildCMSStyles<TextColorProps>({ textColor })}>
                            <div className={styles.title} style={buildCMSStyles<TitleProps>({ title, fontSizeTitle })}>
                                {title}
                            </div>
                            <HtmlContainer
                                className={styles.description}
                                style={buildCMSStyles<SubTitleProps>({
                                    subTitle,
                                    fontSizeSubTitle,
                                })}
                                htmlContent={subTitle}
                            />
                        </div>
                        <div className={styles.pictureContent}>
                            <div className={styles.mainPicture} style={{ order: inverted ? -1 : 1 }}>
                                {mainLink !== '' ? (
                                    <Link to={mainLink}>
                                        <CMSImageContainer picture={mainPicture} />
                                    </Link>
                                ) : (
                                    <CMSImageContainer picture={mainPicture} />
                                )}
                            </div>

                            {list && list.length > 0 && (
                                <div className={styles.itemContainer} style={{ order: inverted ? -2 : 2 }}>
                                    {buildComponentArray<TrendElementProps>(
                                        list,
                                        (childItem: TrendElementProps, key: number): JSX.Element => {
                                            return (
                                                <TrendElement
                                                    titleIsHtml={elementsHaveHtmlTitle}
                                                    {...childItem}
                                                    key={key}
                                                />
                                            );
                                        },
                                    )}
                                </div>
                            )}

                            {children && (
                                <div className={styles.itemContainer} style={{ order: inverted ? -3 : 3 }}>
                                    {React.Children.map(children, child => {
                                        return <div className={styles.item}>{child}</div>;
                                    })}
                                </div>
                            )}
                        </div>
                        <div className={styles.CTA}>
                            {ctaText && <CtaButton ctaText={ctaText} ctaLink={ctaLink} ctaStyle={ctaStyle} />}
                        </div>
                    </div>
                </Wrapper>
            </React.Fragment>
        );
    },
);

export const TrendBlocLoader: React.FC = () => {
    return (
        <div className={styles.container}>
            <Placeholder />
        </div>
    );
};

export const TrendElement: React.FC<TrendElementProps> = React.memo(
    ({ title, picture, pictureLink, titleIsHtml }: TrendElementProps) => {
        const titleValue = readCMSValue(title);
        const link = getDefault(readCMSValue(pictureLink), '');
        return (
            <div className={styles.item}>
                {link !== '' ? (
                    <Link to={link}>
                        <CMSImageContainer picture={picture} placeholderClassName={styles.trendElementLoader} />
                        {titleValue &&
                            (titleIsHtml ? (
                                <HtmlContainer className={styles.pictureCaption} htmlContent={titleValue || ''} />
                            ) : (
                                <p className={styles.pictureCaption}>{titleValue}</p>
                            ))}
                    </Link>
                ) : (
                    <>
                        <CMSImageContainer picture={picture} placeholderClassName={styles.trendElementLoader} />
                        {titleValue &&
                            (titleIsHtml ? (
                                <HtmlContainer className={styles.pictureCaption} htmlContent={titleValue || ''} />
                            ) : (
                                <p className={styles.pictureCaption}>{titleValue}</p>
                            ))}
                    </>
                )}
            </div>
        );
    },
);
