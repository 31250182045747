import React, { PropsWithChildren } from 'react';

import { readCMSValue } from 'modules/shared-cms/utils';

import { TrendBloc } from '../../base/trend-bloc';

import { TrendBlocContainerProps } from './trend-bloc-container-type';

export const TrendBlocContainer: React.FC<TrendBlocContainerProps> = ({
    trendTitle,
    trendFontSizeTitle,
    trendSubTitle,
    trendFontSizeSubTitle,
    trendTextColor,
    trendColor,
    trendMainPicture,
    trendMainPictureLink,
    trendCtaLink,
    trendCtaText,
    trendCtaStyle,
    trendPictures,
    trendInverted,
    trendElementsHaveHtmlTitle,
    children,
}: PropsWithChildren<TrendBlocContainerProps>) => {
    return (
        <TrendBloc
            title={trendTitle}
            fontSizeTitle={trendFontSizeTitle}
            subTitle={readCMSValue(trendSubTitle)}
            fontSizeSubTitle={trendFontSizeSubTitle}
            textColor={trendTextColor}
            color={trendColor}
            mainPicture={trendMainPicture}
            mainLink={trendMainPictureLink}
            ctaLink={trendCtaLink}
            ctaText={trendCtaText}
            ctaStyle={trendCtaStyle}
            list={trendPictures ? trendPictures.iv : []}
            inverted={trendInverted}
            elementsHaveHtmlTitle={trendElementsHaveHtmlTitle}
        >
            {children}
        </TrendBloc>
    );
};
