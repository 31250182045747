import * as React from 'react';
import { Popup } from 'vendors/semantic-ui';

import { readCMSValue, getDefault, buildCMSStyles } from 'modules/shared-cms/utils';
import { TextColorProps, GlobalFontSizeProps } from 'modules/shared-cms/cms-base-type';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { CtaButton, HtmlContainer } from 'modules/shared-ui/components';

import { ShowroomBlocProps } from './showroom-bloc-type';

import styles from './showroom-bloc-component.module.css';

export const ShowroomBloc: React.FC<ShowroomBlocProps> = React.memo((props: ShowroomBlocProps) => {
    const {
        title = getDefault(readCMSValue(props.title), ''),
        address = getDefault(readCMSValue(props.adress), ''),
        ctaProps = {
            ctaText: getDefault(readCMSValue(props.ctaText), ''),
            ctaLink: getDefault(readCMSValue(props.ctaLink), ''),
            ctaStyle: getDefault(readCMSValue(props.ctaStyle), 'normal'),
            target: getDefault(readCMSValue(props.targetBlank), false) ? '_blank' : '_self',
            forceHover: false,
        },
        backgroundColor = getDefault(readCMSValue(props.color), 'rgba(0, 0, 0, 0)'),
        phoneNumber = getDefault(readCMSValue(props.phoneNumber), ''),
        emailAddress = getDefault(readCMSValue(props.emailAdress), ''),
        textColor = readCMSValue(props.textColor),
        fontSizeTitle = readCMSValue(props.fontSizeTitle),
        fontSizeAdress = readCMSValue(props.adressFontSize),
    } = {};
    return (
        <React.Fragment>
            <div className={styles.root} style={{ backgroundColor }}>
                {title !== '' && (
                    <HtmlContainer
                        className={styles.title}
                        style={buildCMSStyles<TextColorProps & GlobalFontSizeProps>({
                            textColor,
                            fontSize: fontSizeTitle,
                        })}
                        htmlContent={title}
                    />
                )}
                <div className={styles.container}>
                    <div className={styles.contactInfo}>
                        <div className={styles.itemContainer}>
                            {address !== '' && (
                                <div className={styles.leftBlock}>
                                    <div className={styles.addrIcon}>
                                        <CMSImageContainer picture={props.showroomPicture} />
                                    </div>
                                    <HtmlContainer
                                        style={buildCMSStyles<TextColorProps & GlobalFontSizeProps>({
                                            textColor,
                                            fontSize: fontSizeAdress,
                                        })}
                                        htmlContent={address}
                                    />
                                </div>
                            )}
                        </div>
                        {(phoneNumber !== '' || emailAddress !== '') && (
                            <div className={styles.itemContainer}>
                                <div className={styles.middleBlock}>
                                    {phoneNumber !== '' && (
                                        <Popup
                                            header="Numéro de téléphone"
                                            position="top center"
                                            trigger={
                                                <a className={styles.link} href={`tel:${phoneNumber}`}>
                                                    <div className={styles.icon}>
                                                        <CMSImageContainer picture={props.phonePicture} />
                                                    </div>
                                                    <div
                                                        className={styles.hideable}
                                                        style={buildCMSStyles<TextColorProps & GlobalFontSizeProps>({
                                                            textColor,
                                                            fontSize: fontSizeAdress,
                                                        })}
                                                    >
                                                        {phoneNumber}
                                                    </div>
                                                </a>
                                            }
                                        />
                                    )}
                                    {emailAddress !== '' && (
                                        <Popup
                                            header="Adresse email"
                                            position="top center"
                                            trigger={
                                                <a className={styles.link} href={`mailto:${emailAddress}`}>
                                                    <div className={styles.icon}>
                                                        <CMSImageContainer picture={props.emailPicture} />
                                                    </div>
                                                    <div
                                                        className={styles.hideable}
                                                        style={buildCMSStyles<TextColorProps & GlobalFontSizeProps>({
                                                            textColor,
                                                            fontSize: fontSizeAdress,
                                                        })}
                                                    >
                                                        {emailAddress}
                                                    </div>
                                                </a>
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.itemContainer}>
                        {ctaProps.ctaText !== '' && (
                            <div className={styles.ctaButton}>
                                <CtaButton {...ctaProps} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});
