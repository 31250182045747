import { WithFetchDatasByIdFromCMS, WithFetchDatasByIdFromCMSInputProps } from 'modules/shared-cms/hocs';

import { compose } from 'redux';
import { TitleBlocAltLayoutProps } from 'modules/shared-cms-advanced/components';
import { QUERY } from './introduction-query';
import { IntroductionLoader } from './introduction-component-loader';
import { IntroductionProps } from '.';

const enhancer = compose<React.ComponentType<WithFetchDatasByIdFromCMSInputProps & TitleBlocAltLayoutProps>>(
    WithFetchDatasByIdFromCMS<IntroductionProps>(QUERY, IntroductionLoader, 'findIntroductionContent'),
);
export default enhancer;
