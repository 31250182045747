import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindRubriqueLandingContent($id: String!)
{
  findRubriqueLandingContent(id: $id) {
    data {
      pageTitle {
        iv
      }      
      seoDescription {
        iv
      }
      seoKeywords {
        iv
      }
      seoThumbnail {
        iv {
          url
        }
      }        
      titleBlocTitle {
        iv
      }
      titleBlocSubTitle {
        iv
      }
      titleBlocWidth {
        iv
      }
      titleBlocTagline {
        iv
      }
      titleBlocTextColor {
        iv
      }
      titleBlocColor {
        iv
      }
      titleBlocCtaLink {
        iv
      }
      titleBlocCtaText {
        iv
      }
      titleBlocCtaStyle {
        iv
      }
      titleBlocCtaLink2 {
        iv
      }
      titleBlocCtaText2 {
        iv
      }
      titleBlocCtaStyle2 {
        iv
      }
      titleBlocPicture {
        iv {
          url
          fileSize
        }
      }
      titleBlocHorizontalPosition {
        iv
      }
      titleBlocJustification {
        iv
      }
      news {
        iv{id}
      }
    }
  }
}
`,
]);
