import * as React from 'react';
import { Link } from 'react-router-dom';

import { buildComponentArray } from 'modules/shared/utils';
import { readCMSValue, getDefault } from 'modules/shared-cms/utils';
import { CMSImageContainer } from 'modules/shared-cms/components';

import { PreFooterBlocProps, PreFooterElementProps } from './pre-footer-bloc-type';

import styles from './pre-footer-bloc-component.module.css';

const PreFooterElement: React.FC<PreFooterElementProps> = React.memo(
    ({ text, picture, pictureLink }: PreFooterElementProps) => {
        const textValue = readCMSValue(text);
        return (
            <div className={styles.item}>
                <Link to={getDefault(readCMSValue(pictureLink), '#')}>
                    <CMSImageContainer picture={picture} />
                    {textValue && <p className={styles.pictureCaption}>{textValue}</p>}
                </Link>
            </div>
        );
    },
);

export const PreFooterBloc: React.FC<PreFooterBlocProps> = React.memo(
    ({ preFooterPicturelist }: PreFooterBlocProps) => {
        return (
            <div className={styles.prefooter}>
                {preFooterPicturelist && preFooterPicturelist.length > 0 && (
                    <div
                        className={
                            preFooterPicturelist.length > 3 ? styles.itemContainerLarge : styles.itemContainerSmall
                        }
                    >
                        {buildComponentArray<PreFooterElementProps>(
                            preFooterPicturelist,
                            (childItem: PreFooterElementProps, key: number): JSX.Element => {
                                return <PreFooterElement {...childItem} key={key} />;
                            },
                        )}
                    </div>
                )}
            </div>
        );
    },
);
