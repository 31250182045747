import React from 'react';

import { readCMSValue } from 'modules/shared-cms/utils';
import { SimpleStaggeredBloc } from '../simple-staggered-bloc';
import { SimpleStaggeredBlocListProps } from './simple-staggered-bloc-list-type';

import styles from './simple-staggered-bloc-list-component.module.css';

export const SimpleStaggeredBlocList: React.FC<SimpleStaggeredBlocListProps> = ({
    className,
    staggeredImages,
    hideEmptyBloc,
}: SimpleStaggeredBlocListProps) => {
    const unwrappedStaggeredImgs = readCMSValue(staggeredImages);
    if (!unwrappedStaggeredImgs || unwrappedStaggeredImgs.length === 0) {
        return <React.Fragment />;
    }

    return (
        <div className={styles.staggeredImagesContainer + (className ? ` ${className}` : '')}>
            {unwrappedStaggeredImgs &&
                unwrappedStaggeredImgs.map(item => {
                    return <SimpleStaggeredBloc {...item} hideEmptyBloc={hideEmptyBloc} />;
                })}
        </div>
    );
};
