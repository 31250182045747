import React from 'react';

import { getDefault, readCMSValue } from 'modules/shared-cms/utils';

import { ClassicAdvantageContainer, ExpandableAdvantageContainer } from './components';
import { AdvantageContainerProps } from './advantage-container-type';

export const AdvantageContainer: React.FC<AdvantageContainerProps> = ({
    advantageTitle,
    advantage,
    titleStyle,
    expander,
    children,
    secondaryTitle,
    ...rest
}: AdvantageContainerProps) => {
    const title = getDefault(readCMSValue(advantageTitle), '');
    const content = getDefault(readCMSValue(advantage), '');

    if (!title && !content) {
        return <React.Fragment />;
    }

    if (expander) {
        return (
            <ExpandableAdvantageContainer
                {...rest}
                title={title}
                titleStyle={titleStyle}
                content={content}
                secondaryTitle={secondaryTitle}
            >
                {children}
            </ExpandableAdvantageContainer>
        );
    }
    return (
        <ClassicAdvantageContainer
            {...rest}
            title={title}
            titleStyle={titleStyle}
            content={content}
            secondaryTitle={secondaryTitle}
        >
            {children}
        </ClassicAdvantageContainer>
    );
};
