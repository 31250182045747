import React from 'react';
import { Placeholder, Responsive } from 'vendors/semantic-ui';

import { configUI } from 'config';

import { useHover } from 'modules/shared/hooks';
import { readCMSValue, buildCMSStyles, getDefault } from 'modules/shared-cms/utils';
import { CMSImageContainer } from 'modules/shared-cms/components';
import { ClickableContainer } from 'modules/shared-ui/components';

import {
    CMSContainerField,
    Justification,
    JustifyProps,
    BackgroundColorProps,
    ContentPositionProps,
    WidthProps,
    Width,
} from 'modules/shared-cms/cms-base-type';
import { TitleBlocProps } from './title-bloc-type';

import styles from './title-bloc-component.module.css';
import { LegendBloc } from './legend-bloc';

interface WrapperContenuProps {
    color?: string | CMSContainerField<string>;
    justification?: Justification | CMSContainerField<Justification>;
    width?: Width | CMSContainerField<Width>;
}

const OuterWrapper: React.FC<ContentPositionProps> = props => {
    const { children } = props;
    return (
        <div className={styles.outerWrapper} style={buildCMSStyles(props)}>
            {children}
        </div>
    );
};

const WrapperContenu: React.FC<WrapperContenuProps> = props => {
    const { children } = props;
    return (
        <div
            className={styles['wrapper-contenu']}
            style={buildCMSStyles<BackgroundColorProps & JustifyProps & WidthProps>(props)}
        >
            {children}
        </div>
    );
};

export const TitleBloc: React.FC<TitleBlocProps> = React.memo((props: TitleBlocProps) => {
    const { bind } = useHover();

    const {
        width = readCMSValue(props.width),
        justification = readCMSValue(props.justification),
        horizontalPosition = readCMSValue(props.horizontalPosition),
        verticalPosition = readCMSValue(props.verticalPosition),
        picture = getDefault(readCMSValue(props.picture), []),
        ctaLink = readCMSValue(props.ctaLink),
        ctaText2 = readCMSValue(props.ctaText2),
        color = readCMSValue(props.color),
        isChild = props.isChild || false,
        blockResponsive = props.blockResponsive || false,
        fullwidth = props.fullWidth || props.blockResponsive || false,
        legendBlocProps: legendBlocProps = {
            ...props,
            disableColorChange: props.blockResponsive,
        },
    } = {};

    return (
        <div
            className={`${styles.rootContainer} ${isChild ? `${styles.isChild}` : ''} ${
                fullwidth ? styles.fullWidth : ''
            }`}
        >
            <ClickableContainer
                link={ctaText2 ? undefined : ctaLink}
                onHover={bind}
                innerClassName={`${styles.background} `}
            >
                <CMSImageContainer picture={picture} style={{ width: '100%' }}>
                    <Responsive
                        as={React.Fragment}
                        minWidth={
                            blockResponsive
                                ? configUI.responsive.mobile.minWidth
                                : configUI.responsive.computer.minWidth
                        }
                    >
                        <OuterWrapper horizontalPosition={horizontalPosition} verticalPosition={verticalPosition}>
                            <WrapperContenu color={color} justification={justification} width={width || 'moyen'}>
                                <div className={styles['inner-wrapper']}>
                                    <LegendBloc {...legendBlocProps} />
                                </div>
                            </WrapperContenu>
                        </OuterWrapper>
                    </Responsive>
                </CMSImageContainer>
            </ClickableContainer>
            {!blockResponsive && (
                <Responsive as={React.Fragment} maxWidth={configUI.responsive.tablet.maxWidth}>
                    <LegendBloc {...legendBlocProps} />
                </Responsive>
            )}
        </div>
    );
});

export const TitleBlocLoader: React.FC = React.memo(() => {
    return (
        <Placeholder style={{ maxWidth: '100%', height: '300px' }}>
            <Placeholder.Image />
        </Placeholder>
    );
});
