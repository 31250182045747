import React from 'react';

import { ShowroomBloc } from '../../components';

import { ShowroomProps } from './showroom-type';

import enhancer from './showroom-enhancer';

const showroom: React.FC<ShowroomProps> = React.memo((props: ShowroomProps) => {
    return (
        <React.Fragment>
            <ShowroomBloc {...props} />
        </React.Fragment>
    );
});

export const Showroom = enhancer(showroom);
