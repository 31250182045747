import { WithFetchDatasByIdFromCMS } from 'modules/shared-cms/hocs';

import { QUERY } from './bloc-actualite-query';
import { BlocActualiteLoader } from './bloc-actualite-loader';
import { BlocActualiteProps } from './bloc-actualite-type';

const BlocActualiteEnhancer = WithFetchDatasByIdFromCMS<BlocActualiteProps>(
    QUERY,
    BlocActualiteLoader,
    'findBlocActualiteContent',
);
export default BlocActualiteEnhancer;
