import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';

import { readCMSValue, buildCMSStyles, getDefault, getDefaultUrlPicture } from 'modules/shared-cms/utils';
import { SubTitleProps, TextColorProps, BackgroundColorProps } from 'modules/shared-cms/cms-base-type';
import { useHover } from 'modules/shared/hooks';
import { CtaButton, ClickableContainer, HtmlContainer } from 'modules/shared-ui/components';

import { ExpanderItemBlocProps } from './expander-item-bloc-type';

import styles from './expander-item-bloc-component.module.css';

export const ExpanderItemBloc: React.FC<ExpanderItemBlocProps> = React.memo((props: ExpanderItemBlocProps) => {
    const { bind } = useHover();
    // TODO : centraliser les valeurs par defaut

    const {
        title = getDefault(readCMSValue(props.title), ''),
        subTitle = readCMSValue(props.subTitle),
        picture = getDefault(readCMSValue(props.picture), []),
        ctaText = readCMSValue(props.ctaText),
        ctaLink = readCMSValue(props.ctaLink),
        ctaStyle = readCMSValue(props.ctaStyle),
        color = readCMSValue(props.color),
    } = {};

    const pictureValue = getDefaultUrlPicture(picture);

    return (
        <div className={`${styles.rootContainer}`}>
            <ClickableContainer link={ctaLink} onHover={bind} innerClassName={`${styles.background} `}>
                <div className={styles.backgroundPicture} style={{ backgroundImage: `url(${pictureValue})` }}>
                    <div style={buildCMSStyles<BackgroundColorProps>({ color })} className={styles.colorWrapper}>
                        {title ? (
                            <div className={styles.titre}>
                                <HtmlContainer htmlContent={title} />
                            </div>
                        ) : (
                            <div className={styles.titre}>&nbsp;</div>
                        )}
                        {subTitle ? (
                            <div
                                className={styles.texte}
                                style={buildCMSStyles<SubTitleProps & TextColorProps>({
                                    subTitle,
                                })}
                            >
                                <HtmlContainer htmlContent={subTitle} />
                            </div>
                        ) : (
                            <div className={styles.texte}>&nbsp;</div>
                        )}
                        {ctaText ? (
                            <div className={styles.ctaButtonContainer}>
                                {ctaText ? (
                                    <div className={styles.ctaButton}>
                                        <CtaButton
                                            forceHover={false}
                                            ctaText={ctaText}
                                            ctaLink={ctaLink}
                                            ctaStyle={ctaStyle}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.ctaButton}>&nbsp;</div>
                                )}
                            </div>
                        ) : (
                            <div>&nbsp;</div>
                        )}
                    </div>
                </div>
            </ClickableContainer>
        </div>
    );
});

export const ExpanderItemBlocLoader: React.FC = React.memo(() => {
    return (
        <Placeholder style={{ maxWidth: '100%', height: '300px' }}>
            <Placeholder.Image />
        </Placeholder>
    );
});
