import React from 'react';

import { BackgroundColorProps, TextColorProps, TitleProps, SubTitleProps } from 'modules/shared-cms/cms-base-type';
import { buildCMSStyles, getDefault, readCMSValue } from 'modules/shared-cms/utils';
import { CtaButton } from 'modules/shared-ui/components';
import { Carousel } from 'modules/shared-ui-advanced/components';

import { ModelBloc } from '../model-bloc';
import { PromotionBlocProps } from './promotion-bloc-type';

import styles from './promotion-bloc-component.module.css';

const Wrapper: React.FC<BackgroundColorProps> = props => {
    const { children } = props;
    return (
        <div className={styles.wrapper} style={buildCMSStyles<BackgroundColorProps>(props)}>
            {children}
        </div>
    );
};
const Content: React.FC<TextColorProps> = props => {
    const { children } = props;
    return (
        <div className={styles.content} style={buildCMSStyles<TextColorProps>(props)}>
            {children}
        </div>
    );
};

export const PromotionBloc: React.FC<PromotionBlocProps> = React.memo(
    (props: React.PropsWithChildren<PromotionBlocProps>) => {
        const {
            superTitle = getDefault(readCMSValue(props.superTitle), ''),
            title = getDefault(readCMSValue(props.title), ''),
            fontSizeTitle = getDefault(readCMSValue(props.fontSizeTitle), 30),
            subTitle = readCMSValue(props.subTitle),
            fontSizeSubTitle = getDefault(readCMSValue(props.fontSizeSubTitle), 16),
            textColor = readCMSValue(props.textColor),
            ctaText = readCMSValue(props.ctaText),
            ctaLink = readCMSValue(props.ctaLink),
            ctaStyle = readCMSValue(props.ctaStyle),
            color = readCMSValue(props.color),
            items = getDefault(readCMSValue(props.items), []),
            titleClassName = `${styles.title} ${props.titleClassName || ''}`,
        } = {};

        const titleStyle = buildCMSStyles<TitleProps>({ title, fontSizeTitle });
        const subTitleStyle = buildCMSStyles<SubTitleProps>({
            subTitle,
            fontSizeSubTitle,
        });

        return (
            <React.Fragment>
                {superTitle !== '' && (
                    <div className={styles.superTitle}>
                        <span>{superTitle}</span>
                    </div>
                )}
                <Wrapper color={color}>
                    <Content textColor={textColor}>
                        <div className={styles.textContent}>
                            <div className={titleClassName} style={titleStyle}>
                                {title}
                            </div>
                            <div className={styles.description} style={subTitleStyle}>
                                {subTitle}
                            </div>
                        </div>
                        {(items.length > 0 || props.children) && (
                            <div className={styles.carousel}>
                                <Carousel cards={4} dark auto>
                                    {items.length > 0
                                        ? items.slice().map(item => {
                                              return (
                                                  <React.Fragment key={Math.random()}>
                                                      <ModelBloc className="modelBloc" {...item} />
                                                  </React.Fragment>
                                              );
                                          })
                                        : props.children}
                                </Carousel>
                            </div>
                        )}
                    </Content>
                    {ctaText && ctaLink ? (
                        <div className={styles.ctaButtonContainer}>
                            <CtaButton ctaLink={ctaLink} ctaText={ctaText} ctaStyle={ctaStyle} />
                        </div>
                    ) : (
                        ''
                    )}
                </Wrapper>
            </React.Fragment>
        );
    },
);
