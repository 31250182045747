import React from 'react';
import { Placeholder, Grid } from 'vendors/semantic-ui';

import { buildComponentArray } from 'modules/shared/utils';
import { readCMSValue, buildCMSStyles, getDefault, getDefaultUrlPicture } from 'modules/shared-cms/utils';
import { BackgroundColorProps, TitleProps } from 'modules/shared-cms/cms-base-type';
import { HtmlContainer } from 'modules/shared-ui/components';

import { RibbonBlocProps, RibbonElementProps } from './ribbon-bloc-type';

import styles from './ribbon-bloc-component.module.css';

interface RibbonBlocLoaderProps {
    elemNumber: number;
}

export const RibbonBloc: React.FC<RibbonBlocProps> = React.memo(({ title, color, list, picture }: RibbonBlocProps) => {
    const pic = getDefaultUrlPicture(picture);
    const uwList = readCMSValue(list);
    return (
        <div className={styles.wrapper}>
            <div className={styles.titleContainer}>
                <h1 className={styles.title} style={buildCMSStyles<TitleProps>({ title })}>
                    {getDefault(readCMSValue(title), '')}
                </h1>
            </div>
            <div className={styles.backgroundColor} style={buildCMSStyles<BackgroundColorProps>({ color })}>
                <div className={styles.itemColorContainer}>
                    {uwList &&
                        uwList.map((item, index) => {
                            const bgColor = getDefault(readCMSValue(item.color), '');
                            return (
                                <div
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${item.title}${index}`}
                                    className={styles.itemColor}
                                    style={{ background: `${bgColor}` }}
                                />
                            );
                        })}
                </div>
                {pic && <img src={pic} className={styles.backgroundImage} alt="" />}
                <RibbonContainer>
                    {uwList &&
                        buildComponentArray<RibbonElementProps>(
                            uwList,
                            (childItem: RibbonElementProps, key: number): JSX.Element => {
                                return <RibbonElement {...childItem} key={key} />;
                            },
                        )}
                </RibbonContainer>
            </div>
        </div>
    );
});

const RibbonElement: React.FC<RibbonElementProps> = React.memo(
    ({ title: wpTitle, picture: wpPicture, subTitle: wpSubTitle }: RibbonElementProps) => {
        const {
            title = getDefault(readCMSValue(wpTitle), ''),
            picture = getDefaultUrlPicture(wpPicture),
            subTitle = getDefault(readCMSValue(wpSubTitle), ''),
        } = {};

        return (
            <div className={styles.backgroundElement}>
                {picture && picture.length > 0 && <img src={picture} alt={title} className={styles.imageElement} />}
                <div className={styles.textWrapper}>
                    <div className={styles.titleElement}>
                        <HtmlContainer htmlContent={title} />
                    </div>
                    <div className={styles.subTitleElement}>
                        <HtmlContainer htmlContent={subTitle} />
                    </div>
                </div>
            </div>
        );
    },
);

const RibbonContainer: React.FC = React.memo(props => {
    const { children } = props;
    return (
        <Grid className="full-height">
            {React.Children.toArray(children).map((child, key) => {
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid.Column key={key} mobile={8} tablet={8} computer={4}>
                        {child}
                    </Grid.Column>
                );
            })}
        </Grid>
    );
});

export const RibbonBlocLoader: React.FC<RibbonBlocLoaderProps> = ({ elemNumber }: RibbonBlocLoaderProps) => {
    return (
        <div className={styles.background}>
            <Placeholder className={styles.title} style={{ maxWidth: '100%' }}>
                <Placeholder.Header>
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
            <RibbonContainer>
                {[...Array(elemNumber)].map((_, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Placeholder key={i}>
                        <Placeholder.Image style={{ height: '200px' }} />
                    </Placeholder>
                ))}
            </RibbonContainer>
        </div>
    );
};
