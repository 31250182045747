import React from 'react';

import { HtmlContainer } from 'modules/shared-ui/components';
import { getDefault, readCMSValue, buildCMSStyles } from 'modules/shared-cms/utils';
import { TextColorProps, GlobalFontSizeProps } from 'modules/shared-cms/cms-base-type';

import { DescriptionProps } from './description-type';

import styles from './description-component.module.css';

export const Description: React.FC<DescriptionProps> = ({
    text: descText,
    textColor: descTextColor,
    fontSize: descFontSize,
    small = false,
}: DescriptionProps) => {
    const {
        text = getDefault(readCMSValue(descText), ''),
        textColor = getDefault(readCMSValue(descTextColor), '#000000'),
        fontSize = getDefault(readCMSValue(descFontSize), 14),
    } = {};

    if (text) {
        return (
            <div
                className={`${styles.description} ${!small ? styles.large : ''}`}
                style={buildCMSStyles<TextColorProps & GlobalFontSizeProps>({
                    textColor,
                    fontSize,
                })}
            >
                <HtmlContainer htmlContent={text} />
            </div>
        );
    }
    return <React.Fragment></React.Fragment>;
};
