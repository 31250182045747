import * as React from 'react';
import { Placeholder } from 'vendors/semantic-ui';

import { readCMSValue } from 'modules/shared-cms/utils';

import { TitleBlocProps } from '../title-bloc/title-bloc-type';
import { ExpanderItemBloc } from './expander-item-bloc';
import { ExpanderBlocProps } from './expander-bloc-type';

import styles from './expander-bloc-component.module.css';

export const ExpanderBloc: React.FC<ExpanderBlocProps> = React.memo(({ titles: titlesWrapper }: ExpanderBlocProps) => {
    const titles = readCMSValue(titlesWrapper);

    return (
        <div>
            {titles &&
                titles.length > 0 &&
                (titles as TitleBlocProps[]).map((title, key) => (
                    // TODO : voir  a binder l'id du contenu sur la key
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={key} className={styles.itemWrapper}>
                        <ExpanderItemBloc
                            picture={title.picture}
                            title={title.title}
                            subTitle={title.subTitle}
                            color={title.color}
                            ctaText={title.ctaText}
                            ctaStyle={title.ctaStyle}
                            ctaLink={title.ctaLink}
                        />
                    </div>
                ))}
        </div>
    );
});

export const ExpanderBlocLoader: React.FC = React.memo(() => {
    return (
        <Placeholder className={styles.loader} style={{ maxWidth: '100%' }}>
            <Placeholder.Image />
        </Placeholder>
    );
});
