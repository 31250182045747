import React from 'react';

import { TrendBlocContainer } from '../../components';
import { TendanceProps } from './tendance-type';

import enhancer from './tendance-enhancer';

import styles from './tendance-component.module.css';

const tendance: React.FC<TendanceProps> = React.memo(({ trendMainPicture, trendPictures, ...rest }: TendanceProps) => {
    return (
        <React.Fragment>
            {trendMainPicture || (trendPictures && trendPictures.iv.length > 0) ? (
                <div className={styles.trendBloc}>
                    <TrendBlocContainer trendMainPicture={trendMainPicture} trendPictures={trendPictures} {...rest} />
                </div>
            ) : (
                ''
            )}
        </React.Fragment>
    );
});

export const Tendance = enhancer(tendance);
