import React from 'react';

import { LandingRubriqueExpander, RubriqueLanding, SousRubriqueLanding, OurCustomers } from '../pages';

export const dynamicSelector = (template: string, id: string): JSX.Element | undefined => {
    switch (template) {
        case 'rubrique-landing-expander':
            return <LandingRubriqueExpander id={id} />;
        case 'rubrique-landing':
            return <RubriqueLanding id={id} />;
        case 'sous-rubrique-landing':
            return <SousRubriqueLanding id={id} />;
        case 'nos-maries':
            return <OurCustomers id={id} />;
        default:
            return undefined;
    }
};
