import React from 'react';

import { TrendBlocLoader } from '../../components';

import styles from './tendance-component.module.css';

export const TendanceLoader: React.FC = React.memo(() => {
    return (
        <React.Fragment>
            <div className={`${styles.root}`}>
                <TrendBlocLoader />
            </div>
        </React.Fragment>
    );
});
