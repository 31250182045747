import React from 'react';
import { Placeholder } from 'vendors/semantic-ui';
import { Embed } from 'modules/shared-ui-advanced/vendors/semantic-ui';
import { readCMSValue, getDefaultUrlPicture, getDefault } from 'modules/shared-cms/utils';
import { HtmlContainer } from 'modules/shared-ui/components';
import { VideoBlocProps } from './video-bloc-type';

import styles from './video-bloc-component.module.css';

export const VideoBloc: React.FC<VideoBlocProps> = React.memo(
    ({ videoLink, videoPlaceholder, videoText }: VideoBlocProps) => {
        const placeholder = getDefaultUrlPicture(videoPlaceholder);
        const videoTitle = getDefault(readCMSValue(videoText), '');

        return (
            <React.Fragment>
                {videoTitle && <HtmlContainer className={styles.videoTitle} htmlContent={videoTitle} />}
                {videoLink && (
                    <div className={styles.videoContainer}>
                        <Embed icon="right circle arrow" url={readCMSValue(videoLink)} placeholder={placeholder} />
                    </div>
                )}
            </React.Fragment>
        );
    },
);

export const VideoBlocLoader: React.FC = () => {
    return (
        <div className={styles.videoContainer}>
            <Placeholder style={{ height: 400 }} fluid>
                <Placeholder.Image />
            </Placeholder>
        </div>
    );
};
