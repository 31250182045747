import gql from 'graphql-tag';

export const QUERY = gql([
    `
query FindActualiteContent($id: String!) {
    findActualiteContent(id: $id) {
        data {
            intro { iv }
            introFontSize { iv }
            introTextColor { iv }
            news {
                iv {
                    title
                    subTitle
                    ctaLink
                    ctaText
                    ctaStyle
                    picture {
                        url
                        fileSize
                    }
                }
            }
        }
    }
}
`,
]);
